/// <reference path="core.ts" / >

//FutureReadyPAIndex
class FutureReadyPAIndex {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["futureReadyPAIndexField"];
        let statewide = document.getElementById("futureReadyPAIndexMeasureASS2030GAddRow");
        if (statewide !== null)
            statewide.addEventListener("click", (e: Event) => this.addStrengthAllStudent());

        let improvement = document.getElementById("futureReadyPAIndexMeasureSIOSITAddRow");
        if (improvement !== null)
            improvement.addEventListener("click", (e: Event) => this.addStrengthStatewideImprovement());

        let challenge = document.getElementById("futureReadyPAIndexChallengeAddRow");
        if (challenge !== null)
            challenge.addEventListener("click", (e: Event) => this.addChallenge());

        let schoollevelstrength = document.getElementById("futureReadyPAIndexSchoolLevelStrengthsAddRow");
        if (schoollevelstrength !== null)
            schoollevelstrength.addEventListener("click", (e: Event) => this.addSchoolLevelStrength());

        let schoollevelchallenge = document.getElementById("futureReadyPAIndexSchoolLevelChallengesAddRow");
        if (schoollevelchallenge !== null)
            schoollevelchallenge.addEventListener("click", (e: Event) => this.addSchoolLevelChallenge());

        let gradelevelstudentgroupstrength = document.getElementById("futureReadyPAIndexGradeLevelAndStudentGroupStrengthsAddRow");
        if (gradelevelstudentgroupstrength !== null)
            gradelevelstudentgroupstrength.addEventListener("click", (e: Event) => this.addGradeLevelStudentGroupStrength());

        let gradelevelstudentgroupchallenge = document.getElementById("futureReadyPAIndexGradeLevelAndStudentGroupChallengesAddRow");
        if (gradelevelstudentgroupchallenge !== null)
            gradelevelstudentgroupchallenge.addEventListener("click", (e: Event) => this.addGradeLevelStudentGroupChallenge());

        let saveButton = document.getElementById("futureReadyPAIndexSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];

        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("futureReadyPAIndexField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveFutureReadyPAIndex', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addStrengthAllStudent() {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexMeasureASS2030G"));

        let newRow: number = 0;
        let strengthList = document.getElementsByClassName("frStrengthMeasureASS2030G");
        let _that = this;
        for (let strength of strengthList) {
            let element = <HTMLSelectElement>strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexStrength');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=ASS2030G");
    }

    addStrengthStatewideImprovement() {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexMeasureSIOSIT"));

        let newRow: number = 0;
        let strengthList = document.getElementsByClassName("frStrengthMeasureSIOSIT");
        let _that = this;
        for (let strength of strengthList) {
            let element = <HTMLSelectElement>strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexStrength');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Statewide Strength Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=SIOSIT");
    }

    addChallenge() {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexChallenge"));

        let newRow: number = 0;
        let challengeList = document.getElementsByClassName("frChallenge");
        let _that = this;
        for (let challenge of challengeList) {
            let element = <HTMLSelectElement>challenge;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexChallenge');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "");
    }

    addSchoolLevelStrength() {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexSchoolLevelStrengths"));

        let newRow: number = 0;
        let strengthList = document.getElementsByClassName("frSchoolLevelStrengths");
        let _that = this;
        for (let strength of strengthList) {
            let element = <HTMLSelectElement>strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexSchoolLevel');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Strengths");
    }

    addSchoolLevelChallenge() {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById("futureReadyPAIndexSchoolLevelChallenges"));

        let newRow: number = 0;
        let challengeList = document.getElementsByClassName("frSchoolLevelChallenges");
        let _that = this;
        for (let challenge of challengeList) {
            let element = <HTMLSelectElement>challenge;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexSchoolLevel');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Challenges");
    }

    addGradeLevelStudentGroupStrength() {
        let core = new Core();
        let newRow: number = 0;
        let _that = this;

        let gradeLevelStudentGroupFields = document.getElementsByClassName("frGradeLevelAndStudentGroupStrengths");
        for (let glsg of gradeLevelStudentGroupFields) {
            let glsgElement = <HTMLInputElement>glsg;
            if (newRow < parseInt(glsgElement.dataset.row))
                newRow = parseInt(glsgElement.dataset.row);
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexGradeLevelAndStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#futureReadyPAIndexGradeLevelAndStudentGroupStrengths > div > div").append(xhr.responseText);
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Strengths");
    }

    addGradeLevelStudentGroupChallenge() {
        let core = new Core();
        let newRow: number = 0;
        let _that = this;

        let gradeLevelStudentGroupFields = document.getElementsByClassName("frGradeLevelAndStudentGroupChallenges");
        for (let glsg of gradeLevelStudentGroupFields) {
            let glsgElement = <HTMLInputElement>glsg;
            if (newRow < parseInt(glsgElement.dataset.row))
                newRow = parseInt(glsgElement.dataset.row);
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexGradeLevelAndStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#futureReadyPAIndexGradeLevelAndStudentGroupChallenges > div > div").append(xhr.responseText);
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newRow=" + newRow + "&label=Challenges");
    }

}

//Future Ready PA Academics
class FutureReadyPAAcademics {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["futureReadyPAAcademicsField"];
        let elaButton = document.getElementById("futureReadyPAAcademicsAddDataSourceELA");
        if (elaButton !== null)
            elaButton.addEventListener("click", (e: Event) => this.addDataSource("futureReadyPAAcademicsELA", "ELA", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));

        let mathButton = document.getElementById("futureReadyPAAcademicsAddDataSourceMath");
        if (mathButton !== null)
            mathButton.addEventListener("click", (e: Event) => this.addDataSource("futureReadyPAAcademicsMath", "Mathematics", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));

        let steeButton = document.getElementById("futureReadyPAAcademicsAddDataSourceSTEE");
        if (steeButton !== null)
            steeButton.addEventListener("click", (e: Event) => this.addDataSource("futureReadyPAAcademicsSTEE", "STEE", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));

        let saveButton = document.getElementById("futureReadyPAAcademicsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("futureReadyPAAcademicsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("futureReadyPAAcademicsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveFutureReadyPAAcademics', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataSource(tableName: string, pageSection: string, className: string, pageCode: string) {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/StudentPerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }
}

//Related Academics
class RelatedAcademics {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["relatedAcademicsField"];
        let careerReadinessButton = document.getElementById("relatedAcademicsAddDataSourceCareerReadiness");
        if (careerReadinessButton !== null)
            careerReadinessButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsCareerReadiness", "Career", "relatedAcademicsField", "spdrelatedacademics"));

        let careerAndTechButton = document.getElementById("relatedAcademicsAddDataSourceCareerAndTech");
        if (careerAndTechButton !== null)
            careerAndTechButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsCareerAndTech", "CTEP", "relatedAcademicsField", "spdrelatedacademics"));

        let artsAndHumanitiesButton = document.getElementById("relatedAcademicsAddDataSourceArtsAndHumanities");
        if (artsAndHumanitiesButton !== null)
            artsAndHumanitiesButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsArtsAndHumanities", "Arts", "relatedAcademicsField", "spdrelatedacademics"));

        let environmentAndEcologyButton = document.getElementById("relatedAcademicsAddDataSourceEnvironmentAndEcology");
        if (environmentAndEcologyButton !== null)
            environmentAndEcologyButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsEnvironmentAndEcology", "EAE", "relatedAcademicsField", "spdrelatedacademics"));

        let familyAndConsumerButton = document.getElementById("relatedAcademicsAddDataSourceFamilyAndConsumer");
        if (familyAndConsumerButton !== null)
            familyAndConsumerButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsFamilyAndConsumer", "FCS", "relatedAcademicsField", "spdrelatedacademics"));

        let healthAndSafetyButton = document.getElementById("relatedAcademicsAddDataSourceHealthAndSafety");
        if (healthAndSafetyButton !== null)
            healthAndSafetyButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsHealthAndSafety", "HSPE", "relatedAcademicsField", "spdrelatedacademics"));

        let socialStudiesButton = document.getElementById("relatedAcademicsAddDataSourceSocialStudies");
        if (socialStudiesButton !== null)
            socialStudiesButton.addEventListener("click", (e: Event) => this.addDataSource("relatedAcademicsSocialStudies", "SS", "relatedAcademicsField", "spdrelatedacademics"));

        let saveButton = document.getElementById("relatedAcademicsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));
                                                      
        let noCTEPCheckbox = document.getElementById("relatedAcademicsCareerandTechnicalEducationCTEProgramsOmit");
        if (noCTEPCheckbox !== null)
            noCTEPCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsCareerAndTech", "relatedAcademicsCareerandTechnicalEducationCTEProgramsOmit"))

        let noArtsAndHumanitiesCheckbox = document.getElementById("relatedAcademicsArtsandHumanitiesOmit");
        if (noArtsAndHumanitiesCheckbox !== null)
            noArtsAndHumanitiesCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsArtsAndHumanities", "relatedAcademicsArtsandHumanitiesOmit"));

        let noEnvironmentAndEcologyCheckbox = document.getElementById("relatedAcademicsEnvironmentandEcologyOmit");
        if (noEnvironmentAndEcologyCheckbox !== null)
            noEnvironmentAndEcologyCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsEnvironmentAndEcology", "relatedAcademicsEnvironmentandEcologyOmit"));

        let noFamilyAndConsumerCheckbox = document.getElementById("relatedAcademicsFamilyandConsumerSciencesOmit");
        if (noFamilyAndConsumerCheckbox !== null)
            noFamilyAndConsumerCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsFamilyAndConsumer", "relatedAcademicsFamilyandConsumerSciencesOmit"));

        let noHealthAndSafetyCheckbox = document.getElementById("relatedAcademicsHealthSafetyandPhysicalEducationOmit");
        if (noHealthAndSafetyCheckbox !== null)
            noHealthAndSafetyCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsHealthAndSafety", "relatedAcademicsHealthSafetyandPhysicalEducationOmit"));

        let noSocialStudiesCheckbox = document.getElementById("relatedAcademicsSocialStudiesCivicsandGovernmentEconomicsGeographyHistoryOmit");
        if (noSocialStudiesCheckbox !== null)
            noSocialStudiesCheckbox.addEventListener("click", (e: Event) => this.noPrograms("relatedAcademicsSocialStudies", "relatedAcademicsSocialStudiesCivicsandGovernmentEconomicsGeographyHistoryOmit"));


        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("relatedAcademicsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("relatedAcademicsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("relatedAcademicsCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK = 0;
            let rowNumber = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.dataset.row && htmlElement.dataset.row !== "0") {
                rowNumber = parseInt(htmlElement.dataset.row);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveRelatedAcademics', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk))
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataSource(tableName: string, pageSection: string, className: string, pageCode: string) {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/StudentPerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }

    noPrograms(tableName: string, checkboxName: string) {
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let checkbox = <HTMLInputElement>document.getElementById(checkboxName);

        for (let input of inputBoxes) {
            if (checkbox.checked) {
                input.value = " ";
                input.readOnly = true;
            }
            else {
                input.readOnly = false;
                input.value = "";
            }
        }
    }

    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("relatedAcademicsOmitCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let tableName = element.getAttribute("data-tablename");
            let table = (<HTMLTableElement>document.getElementById(tableName));
            let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");

            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
}

//Equity Considerations
class EquityConsiderations {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["equityConsiderationsField"];
        let studentEnglishLearnersButton = document.getElementById("equityConsiderationsAddStudentGroupEnglishLearners");
        if (studentEnglishLearnersButton !== null)
            studentEnglishLearnersButton.addEventListener("click", (e: Event) => this.addDataSource("equityConsiderationsEnglishLearners", "EL", "equityConsiderationsField", "spdequityconsiderations"));

        let studentWithDisabilitiesButton = document.getElementById("equityConsiderationsAddStudentGroupStudentsWithDisabilities");
        if (studentWithDisabilitiesButton !== null)
            studentWithDisabilitiesButton.addEventListener("click", (e: Event) => this.addDataSource("equityConsiderationsStudentsWithDisabilities", "DIS", "equityConsiderationsField", "spdequityconsiderations"));

        let studentsConsideredEconomicallyDisadvantagedButton = document.getElementById("equityConsiderationsAddStudentGroupEconomicallyDisadvantaged");
        if (studentsConsideredEconomicallyDisadvantagedButton !== null)
            studentsConsideredEconomicallyDisadvantagedButton.addEventListener("click", (e: Event) => this.addDataSource("equityConsiderationsEconomicallyDisadvantaged", "ED", "equityConsiderationsField", "spdequityconsiderations"));

        let groupsByRace = document.getElementById("equityConsiderationsAddStudentGroupGroupsByRace");
        if (groupsByRace !== null)
            groupsByRace.addEventListener("click", (e: Event) => this.addStudentGroup("equityConsiderationsGroupsByRace", "equityConsiderationsDropdownField"));

        let saveButton = document.getElementById("equityConsiderationsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let noEnglishLearnersCheckbox = document.getElementById("equityConsiderationsEnglishLearnersOmit");
        if (noEnglishLearnersCheckbox !== null)
            noEnglishLearnersCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsEnglishLearners", "equityConsiderationsEnglishLearnersOmit"))

        let noStudentWithDisabilitiesCheckbox = document.getElementById("equityConsiderationsStudentswithDisabilitiesOmit");
        if (noStudentWithDisabilitiesCheckbox !== null)
            noStudentWithDisabilitiesCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsStudentsWithDisabilities", "equityConsiderationsStudentswithDisabilitiesOmit"));

        let noStudentsConsideredEconomicallyDisadvantagedCheckbox = document.getElementById("equityConsiderationsStudentsConsideredEconomicallyDisadvantagedOmit");
        if (noStudentsConsideredEconomicallyDisadvantagedCheckbox !== null)
            noStudentsConsideredEconomicallyDisadvantagedCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsEconomicallyDisadvantaged", "equityConsiderationsStudentsConsideredEconomicallyDisadvantagedOmit"));

        let noStudentsByRaceEthnicityCheckbox = document.getElementById("equityConsiderationsStudentGroupsbyRaceEthnicityOmit");
        if (noStudentsByRaceEthnicityCheckbox !== null)
            noStudentsByRaceEthnicityCheckbox.addEventListener("click", (e: Event) => this.noPrograms("equityConsiderationsGroupsByRace", "equityConsiderationsStudentGroupsbyRaceEthnicityOmit"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("equityConsideraionsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("equityConsiderationsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("equityConsiderationsDropdownField");
        let rowCount = 0;
        for (let element of allElements) {
            let htmlElement = <HTMLSelectElement>element;
            let planPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.selectedIndex > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: htmlElement.value,
                    RowNbr: rowCount,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
                rowCount++;
            }
        }

        allElements = document.getElementsByClassName("equityConsiderationsCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveEquityConsiderations', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addDataSource(tableName: string, pageSection: string, className: string, pageCode: string) {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/EquityConsiderationsStudentGroupText', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }

    addStudentGroup(tableName: string, classNameBase: string) {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/EquityConsiderationsStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Student Group Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount);
    }

    noPrograms(tableName: string, checkboxName: string) {
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let checkbox = <HTMLInputElement>document.getElementById(checkboxName);

        for (let input of inputBoxes) {
            if (checkbox.checked) {
                input.value = " ";
                input.readOnly = true;
            }
            else {
                input.readOnly = false;
                input.value = "";
            }
        }
    }

    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("equityConsiderationsOmitCheckField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let tableName = element.getAttribute("data-tablename");
            let table = (<HTMLTableElement>document.getElementById(tableName));
            let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");

            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
}

//Supplemental LEA Plans
class SupplementalLEAPlans {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["supplementalLEAField"];
        let addAPlanButton = document.getElementById("supplementalLEAPlansAddPlan");
        if (addAPlanButton !== null)
            addAPlanButton.addEventListener("click", (e: Event) => this.addPlan("supplementalLEAPlans", "supplementalLEAPlan"));

        let saveButton = document.getElementById("supplementalLEAPlansSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("supplementalLEAForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("supplementalLEAField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {

                let toDelete = (htmlElement.value === "");

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveSupplementalLEAPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk))
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addPlan(tableName: string, classNameBase: string) {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/SupplementalLEAPlansAddPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount);

    }
}

//Designated Schools
class DesignatedSchools {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["designatedSchoolsField"];
        let addChallengeButton = document.getElementById("designatedSchoolsAddChallenge");
        if (addChallengeButton !== null)
            addChallengeButton.addEventListener("click", (e: Event) => this.addChallenge("designatedSchoolsChallenges"));

        let saveButton = document.getElementById("designatedSchoolsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("designatedSchoolsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("designatedSchoolsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {

                let toDelete = (htmlElement.value === "");

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveDesignatedSchools', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.doValidation(this.validationClasses);
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    addChallenge(tableName: string) {
        let core = new Core();
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let inputBoxes = table.querySelectorAll<HTMLInputElement>("input");
        let rowCount = 0;

        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }

        rowCount++;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Set/DesignatedSchoolsChallengesAddChallenge', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount);

    }
}

//ConidtionsForLeadership
class ConditionsForLeadership {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["conditionsForLeadershipField", "conditionsForLeadershipSummaryField"];
        let saveButton = document.getElementById("conditionsForLeadershipSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("conditionsForLeadershipForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("conditionsForLeadershipField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("conditionsForLeadershipSummaryField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveConditionsForLeadership', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//SummaryOfStrengths
class SummaryOfStrengths {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = [
            "summaryOfStrengthsField",
            "summaryOfChallengesField",
            "summaryOfStrengthsReflectionsField"
        ];

        let saveButton = document.getElementById("summaryOfStrengthsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allStrengthElements = [];
        let allChallengeElements = [];
        let reflectionElement: IPlanProperty;
        let planElement = document.getElementById("summaryOfStrengthsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("summaryOfStrengthsField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let val = "";

            if (htmlElement.checked) {
                val = "on";
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(htmlElement.dataset.datapointplanpropertypk),
                ButtonRow: 0
            };

            allStrengthElements.push(saveItem);
        }

        allElements = document.getElementsByClassName("summaryOfChallengesField");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let val = "";

            if (htmlElement.checked) {
                val = "on";
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(htmlElement.dataset.datapointplanpropertypk),
                ButtonRow: 0
            };

            allChallengeElements.push(saveItem);
        }

        let reflectionElements = document.getElementsByClassName("summaryOfStrengthsReflectionsField");
        for (let element of reflectionElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };

            reflectionElement = saveItem;
        }

        let allSaveElements = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "Reflections": reflectionElement,
            "PlanFK": planPk
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveSummaryOfStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0 && allSaveElements.Reflections.TextValue === "") {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}