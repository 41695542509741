///<reference path="core.ts" />

//Lea Level User
class LeaLevelUser {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("LEALevelUserSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save());

        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();

        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e: Event) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("LeaLevelUser");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeLeaLevelUserRow(e));
        }
    }

    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e: Event) => this.removeUserAccess(e));
        }
    }

    save() {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];

        let rows = document.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            for (let selectElement of selectElements) {
                let element = <HTMLSelectElement>selectElement;
                selectElementValue = parseInt(element.value);
            }
            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = 0;
                let accessTypeCodeFK: number = 0;
                let isSchool = true;
                if (selectElementValue !== 0 && selectElementValue < 8) {
                    isSchool = false;
                }
                if (isSchool === false) {
                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }

                if (htmlElement.checked) {
                    let saveItem: IEdDirUserAccessCreate = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK,
                        UserEdDirUserPK: ""
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
            xhr.send(JSON.stringify(allSaveElements));
    }

     removeLeaLevelUserRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        let rowNumber = parent.dataset.rownumber;

        parent.parentElement.removeChild(parent);
        core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
    }

    addAnotherPlan(e: Event) {
        let core = new Core();
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = (<HTMLTableElement>document.getElementById(tableName));
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let rowCount = 0;

        for (let row of rows) {
            rowCount++;
        }

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/LeaLevelUserDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                core.createHTMLAlert("alertMessageDiv", "New Student Group Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK);
    }

    removeUserAccess(e: Event) {
        let core = new Core();
        let that = this;
        let lea = new LeaLevelUser();
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                core.createHTMLAlert("alertMessageDiv", "User Access Removed", 'success', 3000, core.pageReload(true, null));
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userFK=" + edDirUserFK);
    }
}

//School Plans
class SchoolPlansAdmin {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("SchoolPlanSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save());

        this.bindDeleteButtons();

        core.activateSubMenu("SchoolPlans");
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.removeSchoolPlansRow(e));
        }
    }


    save() {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject: ISaveSchoolPlans;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = 0;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            let elementInstitutionFK = element.dataset.institutionfk;
            let datepicker = document.querySelector(".adminDatePicker[data-institutionfkcompletiondate='" + elementInstitutionFK + "']");
            let datePickerElement = <HTMLInputElement>datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            selectElementValue = parseInt(element.value);
            if (selectElementValue > -1) {

                let saveItem: IInstitutionPhaseDesignation = {
                    FRCPPInstitutionFK: parseInt(elementInstitutionFK),
                    PhaseFK: 0, //0 for schools.
                    DesignationTypeFK: selectElementValue,
                    DueDate: dueDate
                };

                allSelectElements.push(saveItem);
            }
        }

        let rows = document.querySelectorAll("tr[data-rownumber]");

        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");

            for (let element of allElements) {
                let htmlElement = <HTMLInputElement>element;
                let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
                let institutionFK: number = parseInt(htmlElement.dataset.institutionfk);

                if (htmlElement.checked) {
                    let saveItem: IEdDirUserAccessCreate = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: 8,
                        UserEdDirUserPK: ""
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        saveObject = {
            EdDirUserList: allSaveElements,
            InstitutionList: allSelectElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolPlans', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    removeSchoolPlansRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userFK=" + edDirUserFK);

    }
}