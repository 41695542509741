class MyPlans {
    constructor() {
        this.bindLinkButtons();

        let comprehensivePlansTab = <HTMLButtonElement>document.querySelector(".tablinks.comprehensivePlans");
        if (!comprehensivePlansTab.classList.contains("active") && !comprehensivePlansTab.classList.contains("inactive")) {
            comprehensivePlansTab.addEventListener("click", (e: Event) => this.tabLinks("MyPlans"));
        }

        let schoolPlansTab = <HTMLButtonElement>document.querySelector(".tablinks.schoolPlans");
        if (!schoolPlansTab.classList.contains("active") && !schoolPlansTab.classList.contains("inactive")) {
            schoolPlansTab.addEventListener("click", (e: Event) => this.tabLinks("SchoolPlans"));
        }

        let myFoldersTab = <HTMLButtonElement>document.querySelector(".tablinks.myFolders");
        if (!myFoldersTab.classList.contains("active") && !myFoldersTab.classList.contains("inactive")) {
            myFoldersTab.addEventListener("click", (e: Event) => this.tabLinks("MyFolders"));
        }

        let leaClearButton = <HTMLButtonElement>document.getElementById("leaPlanSearchClear");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());

        let leaSearchButton = <HTMLButtonElement>document.getElementById("leaPlanSearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e: Event) => this.leaSearch());

        let leaPlanName = <HTMLInputElement>document.getElementById("leaPlanLEAName");
        if (leaPlanName !== null)
            leaPlanName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { this.leaSearch(); } });

        let plans = document.getElementsByClassName("plan-box");
        for (let plan of plans) {
            let percent = (<HTMLElement>plan).dataset.percentage;

            let pie = <SVGCircleElement>(<HTMLElement>plan).querySelector(".pieChart");
            let stylePercent = percent;
            if (stylePercent === "100") stylePercent = "101"; //Hacks
            let oneHundred = "100";
            pie.style.strokeDasharray = `${stylePercent} ${oneHundred}`;
        }

        let myPlansRenameButtons = document.getElementsByClassName("myPlansRenameFolder");
        for (let renameButton of myPlansRenameButtons) {
            let renameButtonElement = <HTMLButtonElement>renameButton;

            renameButtonElement.addEventListener("click", (e: Event) => this.showRenameModal(e));
        }

        let myPlansCancelRenameButtons = document.getElementsByClassName("myPlansRenameFolderCancel");
        for (let renameCancelButton of myPlansCancelRenameButtons) {
            let renameCancelButtonElement = <HTMLButtonElement>renameCancelButton;

            renameCancelButtonElement.addEventListener("click", (e: Event) => this.closeRenameModal());
        }

        let myPlansConfirmRenameButtons = document.getElementsByClassName("myPlansRenameFolderConfirm");
        for (let renameConfirmButton of myPlansConfirmRenameButtons) {
            let renameConfirmButtonElement = <HTMLButtonElement>renameConfirmButton;
            
            renameConfirmButtonElement.addEventListener("click", (e: Event) => this.renameFolder(e));
        }

        let myPlansDeleteButtons = document.getElementsByClassName("myPlansDeleteFolder");
        for (let deleteButton of myPlansDeleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteModal(parseInt((<HTMLButtonElement>e.srcElement).dataset.folderpk)));
        }

        let myPlansCancelDeleteButtons = document.getElementsByClassName("myPlansDeleteFolderCancel");
        for (let deleteCancelButton of myPlansCancelDeleteButtons) {
            let deleteCancelButtonElement = <HTMLButtonElement>deleteCancelButton;

            deleteCancelButtonElement.addEventListener("click", (e: Event) => this.closeDeleteModal());
        }

        let myPlansConfirmDeleteButtons = document.getElementsByClassName("myPlansDeleteFolderConfirm");
        for (let deleteConfirmButton of myPlansConfirmDeleteButtons) {
            let deleteConfirmButtonElement = <HTMLButtonElement>deleteConfirmButton;

            deleteConfirmButtonElement.addEventListener("click", (e: Event) => this.deleteFolder(e));
        }

        let showFolderContentsButtons = document.getElementsByClassName("showFolderContents");
        for (let showFolderContentsButton of showFolderContentsButtons) {
            let showFolderContentsButtonElement = <HTMLButtonElement>showFolderContentsButton;;

            showFolderContentsButtonElement.addEventListener("click", (e: Event) => this.showFolderContents(e));
        }

        let myPlansDeleteFolderPlanFromConfirmButton = document.getElementById("myPlansDeleteFolderPlanFromConfirm");
        if (myPlansDeleteFolderPlanFromConfirmButton !== null)
            myPlansDeleteFolderPlanFromConfirmButton.addEventListener("click", (e: Event) => this.confirmDeletePlanFromFolder(e));

        let myPlansDeleteFolderPlanFromCancelButton = document.getElementById("myPlansDeletePlanFromFolderCancel");
        if (myPlansDeleteFolderPlanFromCancelButton !== null)
            myPlansDeleteFolderPlanFromCancelButton.addEventListener("click", (e: Event) => this.cancelDeletePlanFromFolder(e));
    }

    bindFolderContentButtons() {
        let myFolderContentsRenameEditButton = document.getElementById("myFolderContentsRenameEdit");
        if (myFolderContentsRenameEditButton !== null) {
            myFolderContentsRenameEditButton.addEventListener("click", (e: Event) => this.showEditMyFolderContentsRenameFolderName());
        }

        let myFolderContentsRenameSaveButton = document.getElementById("myFolderContentsRenameSave");
        if (myFolderContentsRenameSaveButton !== null) {
            myFolderContentsRenameSaveButton.addEventListener("click", (e: Event) => this.saveMyFolderContentsRenameFolderName(e));
        }

        let myFolderContentsRenameCancelButton = document.getElementById("myFolderContentsRenameCancel");
        if (myFolderContentsRenameCancelButton !== null) {
            myFolderContentsRenameCancelButton.addEventListener("click", (e: Event) => this.cancelMyFolderContentsRenameFolderName())
        }
    }

    showEditMyFolderContentsRenameFolderName() {
        let editButtonSpan = <HTMLSpanElement>document.getElementById("editButtonSpan");
        let saveAndCancelButtonsSpan = <HTMLSpanElement>document.getElementById("saveAndCancelButtonsSpan");
        let editFolderNameSpan = <HTMLSpanElement>document.getElementById("folderContentsEditfolderNameSpan");
        let displayFolderNameSpan = <HTMLSpanElement>document.getElementById("folderContentsFolderNameSpan");

        editButtonSpan.classList.remove("show");
        displayFolderNameSpan.classList.remove("show");
        saveAndCancelButtonsSpan.classList.add("show");
        editFolderNameSpan.classList.add("show");
    }

    saveMyFolderContentsRenameFolderName(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let folderPK = buttonElement.dataset.folderpk;
        let myFolderNameTextValueElement = <HTMLInputElement>document.getElementById("folderContentsEditfolderNameValue");
        let currentValue = myFolderNameTextValueElement.dataset.currentvalue;
        let newValue = myFolderNameTextValueElement.value;
        let core: Core = new Core();

        if (currentValue !== newValue) {
            let saveData = {
                "FolderPK": folderPK,
                "NewValue": newValue
            };

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/RenameFolder', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function() {
                if (xhr.status === 200) {
                    let valueElement = <HTMLSpanElement>document.getElementById("folderContentsFolderNameSpan");
                    valueElement.textContent = newValue;

                    core.createHTMLAlert("alertMessageDiv", "The folder was successfully renamed.", 'success', 3000, null);

                    let editButtonSpan = <HTMLSpanElement>document.getElementById("editButtonSpan");
                    let saveAndCancelButtonsSpan = <HTMLSpanElement>document.getElementById("saveAndCancelButtonsSpan");
                    let editFolderNameSpan = <HTMLSpanElement>document.getElementById("folderContentsEditfolderNameSpan");
                    let displayFolderNameSpan = <HTMLSpanElement>document.getElementById("folderContentsFolderNameSpan");

                    editButtonSpan.classList.add("show");
                    displayFolderNameSpan.classList.add("show");
                    saveAndCancelButtonsSpan.classList.remove("show");
                    editFolderNameSpan.classList.remove("show");
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
            };

            xhr.send(JSON.stringify(saveData));
        } else {
            core.createHTMLAlert("alertMessageDiv", `You need to enter a different name for the folder to rename it.`, 'warning', 3000, null);
        }
    }

    cancelMyFolderContentsRenameFolderName() {
        let editButtonSpan = <HTMLSpanElement>document.getElementById("editButtonSpan");
        let saveAndCancelButtonsSpan = <HTMLSpanElement>document.getElementById("saveAndCancelButtonsSpan");
        let editFolderNameSpan = <HTMLSpanElement>document.getElementById("folderContentsEditfolderNameSpan");
        let displayFolderNameSpan = <HTMLSpanElement>document.getElementById("folderContentsFolderNameSpan");

        editButtonSpan.classList.add("show");
        displayFolderNameSpan.classList.add("show");
        saveAndCancelButtonsSpan.classList.remove("show");
        editFolderNameSpan.classList.remove("show");
    }

    bindLinkButtons() {
        let rsgElements = document.getElementsByClassName("readySetGo");
        for (let rsg of rsgElements) {
            let element = <HTMLButtonElement>rsg;
            var planId = element.dataset.planpk;
            rsg.addEventListener("click", (e: Event) => this.redirect(e, "/Ready/ProfilePlanEssentials/"));
        }

        let monitoringElements = document.getElementsByClassName("planMonitoring");
        for (let monitor of monitoringElements) {
            let monitorElement = <HTMLButtonElement>monitor;
            var planId = monitorElement.dataset.planpk;
            if (!monitorElement.classList.contains("not-available")) {
                monitor.addEventListener("click", (e: Event) => this.redirect(e, "/Monitoring/"));
            }
        }

        let communicationElements = document.getElementsByClassName("planCommunications");
        for (let communication of communicationElements) {
            let communicationElement = <HTMLButtonElement>communication;
            var planId = communicationElement.dataset.planpk;
            if (!communicationElement.classList.contains("not-available")) {
                communication.addEventListener("click", (e: Event) => this.redirect(e, "/PlanCommunications/PlanCommunications/"));
            }
        }
    }

    confirmDeletePlanFromFolder(e: Event) {
        let parentContainerElement = <HTMLDivElement>document.getElementById("myFoldersFolderContents");
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let folderPK = parentContainerElement.dataset.folderpk;
        let planPK = buttonElement.dataset.planpk;
        let core: Core = new Core();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/DeletePlanFromFolder', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function() {
            if (xhr.status === 200) {
                //Remove from dom.
                let allPlans = document.getElementsByClassName("folderContentPlan");
                for (let plan of allPlans) {
                    let planElement = <HTMLDivElement>plan;
                    if (planElement.dataset.planpk === planPK) {
                        planElement.parentNode.removeChild(planElement);
                    }
                }

                let modalId = "myPlansDeletePlanFromFolder";
                let modal: Modal = new Modal(modalId, null);
                modal.hide();

                core.createHTMLAlert("alertMessageDiv", "The plan was successfully removed from this folder.", 'success', 3000, null);
            }
            else {
                core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };

        xhr.send(`folderPK=${folderPK}&planPK=${planPK}`);
    }

    cancelDeletePlanFromFolder(e: Event) {
        let modalId = "myPlansDeletePlanFromFolder";
        let modal: Modal = new Modal(modalId, null);
        modal.hide();
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deletePlanFromFolder");

        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.deletePlanFromFolder(e));
        }
    }

    deletePlanFromFolder(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let modalId = "myPlansDeletePlanFromFolder";
        let modal: Modal = new Modal(modalId, null);
        let planPK = buttonElement.dataset.planpk;
        modal.addAttributeToElement(modalId, "#myPlansDeleteFolderPlanFromConfirm", "planpk", planPK.toString());
        modal.show();
    }

    showFolderContents(e: Event) {
        let thisButton = <HTMLButtonElement>e.srcElement;
        let folderPK = thisButton.dataset.folderpk;

        let folderListingDivElement = <HTMLDivElement>document.getElementById("myFoldersFolderListing");
        let folderContentDivElement = <HTMLDivElement>document.getElementById("myFoldersFolderContents");

        let _that = this;

        let core: Core = new Core();
        core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/ShowFolderContents', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function() {
            if (xhr.status === 200) {
                let element = document.getElementById("myFoldersFolderContents");
                $(element).append(xhr.responseText);
                element.dataset.folderpk = folderPK;

                core.hideLoader();
                folderListingDivElement.classList.remove("show");
                folderContentDivElement.classList.add("show");

                _that.bindDeleteButtons();
                _that.bindLinkButtons();
                _that.bindFolderContentButtons();
            }
            else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };

        xhr.send("folderPK=" + folderPK);
    }

    showRenameModal(e: Event) {
        let folderPK = (<HTMLButtonElement>e.srcElement).dataset.folderpk;
        let currentValue = (<HTMLButtonElement>e.srcElement).dataset.currentvalue;

        let modalId: string = "myPlansRename";
        let modal: Modal = new Modal(modalId, null);
        modal.addAttributeToElement(modalId, ".myPlansRenameFolderConfirm", "folderpk", folderPK);
        modal.addAttributeToElement(modalId, ".myPlansRenameFolderName", "value", currentValue, true);
        modal.addAttributeToElement(modalId, ".myPlansRenameFolderName", "oldvalue", currentValue);

        modal.show();
    }

    closeRenameModal() {
        let close = document.getElementById("closeModalmyPlansRename");
        close.click();
    }

    renameFolder(e: Event) {
        let core = new Core();
        let folderPK = parseInt((<HTMLButtonElement>e.srcElement).dataset.folderpk);
        let textboxId = (<HTMLButtonElement>e.srcElement).dataset.textboxid;
        let textbox = <HTMLInputElement>document.getElementById(textboxId);
        let newValue = textbox.value;
        let oldValue = textbox.dataset.oldvalue;

        let saveData = {
            "FolderPK": folderPK,
            "NewValue": newValue
        };

        if (newValue === oldValue) {
            core.createHTMLAlert("alertMessageDiv", "You must change the current value in order to rename the folder.", "warning", 3000, null);
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/RenameFolder', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload());
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
            };

            xhr.send(JSON.stringify(saveData));
        }
    }

    showDeleteModal(folderPK: number) {
        let modalId: string = "myPlansDelete";
        let modal: Modal = new Modal(modalId, null);
        modal.addAttributeToElement(modalId, ".myPlansDeleteFolderConfirm", "folderpk", folderPK.toString());
        modal.show();
    }

    closeDeleteModal() {
        let close = document.getElementById("closeModalmyPlansDelete");
        close.click();
    }

    deleteFolder(e: Event) {
        let core = new Core();
        let folderPK = parseInt((<HTMLButtonElement>e.srcElement).dataset.folderpk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/DeleteFolder', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload());
            }
            else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };

        xhr.send("folderPK=" + folderPK);
    }

    clearLEASearchFilter() {
        let nameElement = <HTMLInputElement>document.getElementById("leaPlanLEAName");

        nameElement.value = "";

        //TODO: Turn this into a partial view, instead of a reload.
        window.location.href = "/Home/MyPlans";
    }

    leaSearch() {
        let nameElement = <HTMLInputElement>document.getElementById("leaPlanLEAName");

        let name = nameElement.value;
        let url = "/Home/MyPlans/?";
        if (name !== "")
            url += "leaName=" + name;

        window.location.href = url;
    }

    tabLinks(link: string) {
        let url = "/Home/" + link;
        window.location.href = url;
    }

    redirect(e: Event, url: string) {
        let element = <HTMLButtonElement>e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;

        window.location.href = newURL;
    }
}

class SchoolPlans {
    constructor() {
        let rsgElements = document.getElementsByClassName("readySetGo");
        for (let rsg of rsgElements) {
            let element = <HTMLButtonElement>rsg;
            var planId = element.dataset.planpk;
            rsg.addEventListener("click", (e: Event) => this.redirect(e, "/Ready/ProfilePlanEssentials/"));
        }

        let comprehensivePlansTab = <HTMLButtonElement>document.querySelector(".tablinks.comprehensivePlans");
        if (!comprehensivePlansTab.classList.contains("active") && !comprehensivePlansTab.classList.contains("inactive")) {
            comprehensivePlansTab.addEventListener("click", (e: Event) => this.tabLinks("MyPlans"));
        }

        let schoolPlansTab = <HTMLButtonElement>document.querySelector(".tablinks.schoolPlans");
        if (!schoolPlansTab.classList.contains("active") && !schoolPlansTab.classList.contains("inactive")) {
            schoolPlansTab.addEventListener("click", (e: Event) => this.tabLinks("SchoolPlans"));
        }

        let myFoldersTab = <HTMLButtonElement>document.querySelector(".tablinks.myFolders");
        if (!myFoldersTab.classList.contains("active") && !myFoldersTab.classList.contains("inactive")) {
            myFoldersTab.addEventListener("click", (e: Event) => this.tabLinks("MyFolders"));
        }

        let schoolClearButton = <HTMLButtonElement>document.getElementById("schoolPlanSearchClear");
        schoolClearButton.addEventListener("click", (e: Event) => this.clearSchoolSearchFilter());

        let schoolSearchButton = <HTMLButtonElement>document.getElementById("schoolPlanSearchSearch");
        schoolSearchButton.addEventListener("click", (e: Event) => this.schoolSearch());

        let schoolPlanName = <HTMLInputElement>document.getElementById("schoolPlanSchoolName");
        schoolPlanName.addEventListener("keypress", (e: KeyboardEvent) => { if (e.keyCode === 13) { this.schoolSearch(); } });

        let plans = document.getElementsByClassName("plan-box");
        for (let plan of plans) {
            let percent = (<HTMLElement>plan).dataset.percentage;

            let pie = <SVGCircleElement>(<HTMLElement>plan).querySelector(".pieChart");
            let stylePercent = percent;
            if (stylePercent === "100") stylePercent = "101"; //Hacks
            let oneHundred = "100";
            pie.style.strokeDasharray = `${stylePercent} ${oneHundred}`;
        }

        let schoolPlanSaveToLibraryButton = document.getElementById("schoolPlanSaveToLibrary");
        if (schoolPlanSaveToLibraryButton !== null)
            schoolPlanSaveToLibraryButton.addEventListener("click", (e: Event) => this.showSaveToLibrary());

        let myPlansSchoolPlanCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
        for (let myPlansSchoolPlanCheckbox of myPlansSchoolPlanCheckboxes) {
            let myPlanSchoolPlanCheckboxElement = <HTMLInputElement>myPlansSchoolPlanCheckbox;

            myPlanSchoolPlanCheckboxElement.addEventListener("change", (e: Event) => this.schoolPlanCheck(e));
        }

        let createAndSaveButton = document.getElementById("createAndSaveButton");
        createAndSaveButton.addEventListener("click", (e: Event) => this.createAndSave(e))

        let saveToThisFolderButtons = document.getElementsByClassName("saveToThisFolder");
        for (let saveToThisFolderButton of saveToThisFolderButtons) {
            let saveToThisFolderButtonElement = <HTMLButtonElement>saveToThisFolderButton;

            saveToThisFolderButtonElement.addEventListener("click", (e: Event) => this.saveToThisFolder(e));
        }
    }

    schoolPlanCheck(e: Event) {
        let libraryClass = document.getElementsByClassName("library-button");
        for (let lib of libraryClass)
            if ((<HTMLInputElement>e.srcElement).checked)
                lib.classList.add("show");
    }

    showSaveToLibrary() {
        let selectedValues = [];
        let allCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
        for (let checkbox of allCheckboxes)
            if ((<HTMLInputElement>checkbox).checked)
                selectedValues.push((<HTMLInputElement>checkbox).dataset.planfk);

        let modal: Modal = new Modal("addToFolder", null);
        modal.addAttributeToElement("addToFolder", "#createAndSaveButton", "selectedvalues", selectedValues.join(","));
        modal.addAttributeToElement("addToFolder", ".saveToThisFolder", "selectedvalues", selectedValues.join(","));
        modal.show();
    }

    saveToThisFolder(e: Event) {
        let folderPK = (<HTMLButtonElement>e.srcElement).dataset.folderpk;
        let listOfPlans = (<HTMLButtonElement>e.srcElement).dataset.selectedvalues;

        let saveData = {
            "FolderPK": folderPK,
            "PlanList": listOfPlans
        };

        let core: Core = new Core();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/SavePlansToFolder', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
            if (xhr.status === 200) {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Plans added to the existing folder!", 'success', 3000, null);

                let modal: Modal = new Modal("addToFolder", null);
                modal.hide();

                let allCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
                for (let checkbox of allCheckboxes) {
                    let checkboxElement = <HTMLInputElement>checkbox;
                    checkboxElement.checked = false;
                }
            }
            else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };

        xhr.send(JSON.stringify(saveData));
    }

    createAndSave(e: Event) {
        let textValueElement = document.getElementById("newFolderName");
        let textValue = (<HTMLInputElement>textValueElement).value;
        let core: Core = new Core();
        let plansToSave = (<HTMLButtonElement>e.srcElement).dataset.selectedvalues;

        if (textValue === "") {
            core.createHTMLAlert("alertMessageDiv", "You must enter a value in the folder name to create a new folder.", "warning", 3000, null);
        } else {
            let saveData = {
                "FolderName": textValue,
                "PlansToSave": plansToSave
            };

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/CreateFolderAndAddPlans', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "New folder created and plans added!", 'success', 3000, core.pageReload());
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
            };

            xhr.send(JSON.stringify(saveData));
        }
    }

    tabLinks(link: string) {
        let url = "/Home/" + link;
        window.location.href = url;
    }

    redirect(e: Event, url: string) {
        let element = <HTMLButtonElement>e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;

        window.location.href = newURL;
    }

    clearSchoolSearchFilter() {
        let yearElement = <HTMLSelectElement>document.getElementById("schoolPlanPlanYear");
        let nameElement = <HTMLInputElement>document.getElementById("schoolPlanSchoolName");
        let statusElement = <HTMLSelectElement>document.getElementById("schoolPlanStatus");
        let typeElement = <HTMLSelectElement>document.getElementById("schoolPlanPlanType");

        yearElement.selectedIndex = -1;
        nameElement.value = "";
        statusElement.selectedIndex = -1;
        typeElement.selectedIndex = -1;

        //TODO: Turn this into a partial view, instead of a reload.
        window.location.href = "/Home/SchoolPlans";
    }

    schoolSearch() {
        let yearElement = <HTMLSelectElement>document.getElementById("schoolPlanPlanYear");
        let nameElement = <HTMLInputElement>document.getElementById("schoolPlanSchoolName");
        let statusElement = <HTMLSelectElement>document.getElementById("schoolPlanStatus");
        let typeElement = <HTMLSelectElement>document.getElementById("schoolPlanPlanType");

        let year = yearElement.value;
        let name = nameElement.value;
        let status = statusElement.value;
        let type = typeElement.value;

        if (year === "") year = null;
        if (status === "") status = null;
        if (type === "") type = null;

        //TODO: Turn this into a partial view, instead of a reload.
        let url = "/Home/SchoolPlans/?year=" + year + "&statusPK=" + status + "&planTypePK=" + type;
        if (name !== "")
            url += "&schoolName=" + name;

        window.location.href = url;
    }
}