/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Approvals and Signatures
class ApprovalsAndSignatures {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["approvalsAndSignaturesField"];

        let approvalsAndSignaturesSaveButton = document.getElementById("saveApprovalsAndSignatures");
        if (approvalsAndSignaturesSaveButton !== null)
            approvalsAndSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let fileUploader = document.getElementById("approvalsAndSignaturesBoardApproval");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    uploadFile(e: HTMLInputElement) {
        let core: Core = new Core();
        core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("approvalsAndSignaturesBoardApproval");
                        formFile.value = "";
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }

        let planPK = 0;
        let formElement = document.getElementById("approvalsAndSignaturesForm");
        let inputs = document.getElementsByClassName("approvalsAndSignaturesField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/SaveApprovalsAndSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}