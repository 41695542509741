/// <reference path="core.ts" />
/// <reference path="interfaces/common/JQuery.d.ts" />

//AnalyzingStrengthsAndChallenges
class AnalyzingStrengthsAndChallenges {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = [
            "analyzingStrengthsAndChallengesChallenges",
            "analyzingStrengthsAndChallengesPriority",
            "analyzingStrengthsAndChallengesStrengths",
            "analyzingStrengthsAndChallengesChallenges"
        ];

        let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");

        for (let check of checkboxes) {
            check.addEventListener("change", (e: Event) => this.addPriorityChallengeRow(e));
        }

        let saveButton = document.getElementById("analyzingStrengthsAndChallengesSave");
        if(saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));
        
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allChallengeElements = [];
        let allChallengeCheckElements = [];
        let allStrengthElements = [];
        let allPriorityElements = [];
        let planFK = 0;

        let planFKElement = document.getElementById("analyzingForm");
        planFK = parseInt(planFKElement.dataset.planpk);

        //Get all the textboxes for challenges
        let challengeElements = document.getElementsByClassName("analyzingStrengthsAndChallengesChallenges");
        for (let element of challengeElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allChallengeElements.push(thisData);
        }

        //Get all the checkboxes for challenges
        let challengeCheckElements = document.getElementsByClassName("analyzingStrengthsAndChallengesChallengesCheckbox");
        for (let element of challengeCheckElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let checkValue: string = "";
            if (htmlElement.checked)
                checkValue = "on";

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: checkValue,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allChallengeCheckElements.push(thisData);
        }

        //Get all the textboxes for Strengths
        let strengthElements = document.getElementsByClassName("analyzingStrengthsAndChallengesStrengths");
        for (let element of strengthElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allStrengthElements.push(thisData);
        }

        //Get all the textboxes for Priority Statements
        let priorityElements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriority");
        for (let element of priorityElements) {
            let htmlElement = <HTMLInputElement>element;

            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let thisData: IAnalyzingStrengthsAndChallenges = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };

            allPriorityElements.push(thisData);
        }

        let allSaveData: IAnalyzingStrengthsAndChallengesSaveData = {
            Challenges: allChallengeElements,
            ChallengesCheckboxes: allChallengeCheckElements,
            Strengths: allStrengthElements,
            PriorityChallenges: allPriorityElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveAnalyzeStrengthsAndChallengesData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveData.Challenges.length === 0 && allSaveData.ChallengesCheckboxes.length === 0 && allSaveData.PriorityChallenges.length === 0 && allSaveData.Strengths.length === 0) {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveData));
        }
    }

    addPriorityChallengeRow(e: Event) {
        let core = new Core();
        var element = <HTMLInputElement>e.srcElement;
        let planFK = 0;
        let planFKElement = document.getElementById("analyzingForm");
        planFK = parseInt(planFKElement.dataset.planpk);

        if (element.checked) {

            let dataPointPropertyPK = element.dataset.propertypk; //The checkbox Property PK becomes the datapoint
            let dataPointPlanPropertyPK = element.dataset.planpropertypk; //The checkbox Plan Property PK becomes the datapoint
            let discussionPointDataPointPropertyPK = element.dataset.datapointpropertypk;
            let discussionPointDataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
            let currentRow = element.dataset.row;

            let allElements = document.getElementsByClassName("readOnlyChallenges");
            let discussionPointElement: HTMLInputElement;
            for (let discussionElement of allElements) {
                let htmlElement = <HTMLInputElement>discussionElement;
                if (htmlElement.dataset.row === currentRow && htmlElement.dataset.planpropertypk === discussionPointDataPointPlanPropertyPK) {
                    discussionPointElement = htmlElement;
                }
            }

            if (discussionPointElement.value !== "") {

                var numberChecked = 0;
                let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");
                for (let check of checkboxes) {
                    var elementCheckbox = <HTMLInputElement>check;
                    if (elementCheckbox.checked) {
                        numberChecked++;
                    }
                }

                for (let check of checkboxes) {
                    var elementCheckbox = <HTMLInputElement>check;
                    if (!elementCheckbox.checked && numberChecked > 3) {
                        elementCheckbox.setAttribute("disabled", "disabled");
                        elementCheckbox.classList.add("disabled");
                        elementCheckbox.style.cursor = "cursor";
                    }
                    else {
                        elementCheckbox.removeAttribute("disabled");
                        elementCheckbox.classList.remove("disabled");
                        elementCheckbox.style.cursor = "pointer";
                    }
                }

                let discussionPointPlanPropertyPK: number = 0;
                if (discussionPointElement.dataset.planpropertypk !== "")
                    discussionPointPlanPropertyPK = parseInt(discussionPointElement.dataset.planpropertypk);
                let discussionPointPropertyPK: number = 0;
                if (discussionPointElement.dataset.propertypk !== "")
                    discussionPointPropertyPK = parseInt(discussionPointElement.dataset.propertypk);
                let priorityPlanPropertyPK: number = 0;
                if (element.dataset.planpropertypk !== "")
                    priorityPlanPropertyPK = parseInt(element.dataset.planpropertypk);

                let allDiscussionPointReadOnlyElements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityDiscussionPointReadOnly");
                let discussionPointReadOnlyElement: HTMLInputElement;

                for (let discussionReadOnlyElement of allDiscussionPointReadOnlyElements) {
                    let htmldiscussionPointReadOnlyElement = <HTMLInputElement>discussionReadOnlyElement;
                    if (htmldiscussionPointReadOnlyElement.dataset.row === currentRow && htmldiscussionPointReadOnlyElement.dataset.planpropertypk === discussionPointDataPointPlanPropertyPK) {
                        discussionPointReadOnlyElement = htmldiscussionPointReadOnlyElement;
                    }
                }

                if (discussionPointReadOnlyElement != null) {
                    discussionPointReadOnlyElement.value = discussionPointElement.value;
                }
                else {
                    let newRowItem: IPriorityChallengeData = {
                        DiscussionPointPlanPropertyPK: discussionPointPlanPropertyPK,
                        DiscussionPointPropertyPK: discussionPointPropertyPK,
                        DiscussionPointTextValue: discussionPointElement.value,
                        PriorityPlanPropertyPK: priorityPlanPropertyPK,
                        PriorityPropertyPK: parseInt(element.dataset.propertypk),
                        PriorityValue: "on",
                        RowNumber: parseInt(currentRow),
                        PlanFK: planFK
                    };

                    let table = (<HTMLTableElement>document.getElementById("analyzingStrengthsAndChallengesTable"));
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/Go/PriorityChallengesRow', true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            let element = table.querySelector("tbody");
                            $(element).append(xhr.responseText);
                            core.createHTMLAlert("alertMessageDiv", "New Priority Challenge Row Added", 'success', 3000, null);
                        } else {
                            core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                        }
                    };
                    xhr.send(JSON.stringify(newRowItem));
                }
            } else {
                element.checked = false;
            }
        } else {
            //TODO: If the user unchecks the box, do we remove the row and delete the data from the database?
            let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");
            for (let check of checkboxes) {
                var elementCheckbox = <HTMLInputElement>check;
                elementCheckbox.removeAttribute("disabled");
                elementCheckbox.classList.remove("disabled");
                elementCheckbox.style.cursor = "pointer";
            }
        }
    }
}

//GoalSetting
class GoalSetting {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["goalSettingField", "goalSettingLookupField"];
        let saveButton = document.getElementById("goalSettingSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("goalSettingForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let allSaveElements = [];
        let allElements = document.getElementsByClassName("goalSettingField");

        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);
            let buttonRow: number = parseInt(htmlElement.dataset.buttonrow);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let toDelete = (htmlElement.value === "");

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: buttonRow
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("goalSettingLookupField");

        for (let element of allElements) {
            let htmlElement = <HTMLSelectElement>element;
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let rowNumber: number = parseInt(htmlElement.dataset.row);
            let buttonRow: number = parseInt(htmlElement.dataset.buttonrow);

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);

            if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);

            let toDelete = (htmlElement.value === "");

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: buttonRow
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//ActionPlan
class ActionPlan {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = [
            "actionPlanField",
            "actionPlanSelectedMeasurableGoals",
            "actionPlanPDStepField",
            "actionPlanComStepField"
        ];

        let addEvidenceBasedStrategyButton = document.getElementById("addMoreEvidenceBasedStrategies");
        if (addEvidenceBasedStrategyButton !== null) {
            addEvidenceBasedStrategyButton.addEventListener("click", (e: Event) => this.addEvidenceBasedStrategies("actionPlanEvidenceBasedStrategy"));
        }

        let numberTextBox = document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        if (numberTextBox !== null) {
            numberTextBox.addEventListener("change", (e: Event) => this.checkForPlural());
        }

        let saveButton = document.getElementById("actionPlanSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        this.bindCreateActionPlans();
        this.bindAddAnActionStep();
        this.bindMeasurableGoals();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allActionPlans = [];
        let allActionSteps = [];
        let allPdSteps = [];
        let allComSteps = [];
        let allGoals = [];
        let allOthers = [];
        let actionStepOthers = [];

        let allElements = document.getElementsByClassName("actionPlanField");

        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionPlanSelectedMeasurableGoals")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.checked) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("actionPlanPDStepField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("actionPlanComStepField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("evidenceBasedStrategyName") && saveItem !== undefined)
                allActionPlans.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanActionStepField") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanPDStepField") && saveItem !== undefined)
                allPdSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanComStepField") && saveItem !== undefined)
                allComSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanSelectedMeasurableGoals") && saveItem !== undefined)
                allGoals.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                actionStepOthers.push(saveItem);
            else
                if (saveItem !== undefined)
                    allOthers.push(saveItem);
        }

        let saveElement = {
            "ActionPlans": allActionPlans,
            "ActionSteps": allActionSteps,
            "PDSteps": allPdSteps,
            "ComSteps": allComSteps,
            "GoalSteps": allGoals,
            "ActionStepOther": actionStepOthers,
            "Others": allOthers
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionPlans.length === 0 && saveElement.ActionStepOther.length === 0 && saveElement.ActionSteps.length === 0 && saveElement.ComSteps.length === 0 && saveElement.GoalSteps.length === 0 && saveElement.Others.length === 0 && saveElement.PDSteps.length === 0) {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    addActionStep(buttonRow: number) {
        let core = new Core();
        let newRow: number = 0;

        let actionStepFields = document.getElementsByClassName("actionPlanActionStepField");
        for (let action of actionStepFields) {
            let actionElement = <HTMLInputElement>action;
            if (buttonRow === parseInt(actionElement.dataset.buttonrow)) {
                if (newRow < parseInt(actionElement.dataset.row)) {
                    newRow = parseInt(actionElement.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddActionStep', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".actionStepContainer[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "New Action Step Added", 'success', 3000, null);
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newRow=" + newRow + "&buttonRow=" + buttonRow);
    }

    addEvidenceBasedStrategies(classNameBase: string) {
        let core = new Core();
        let addEvidenceBasedStrategyNumber = <HTMLInputElement>document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        let planFKElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(planFKElement.dataset.planfk);
        let _that = this;

        if (addEvidenceBasedStrategyNumber.value === "") {

        } else {
            let value = parseInt(addEvidenceBasedStrategyNumber.value);

            let table = (<HTMLTableElement>document.getElementById("evidenceBasedStrategy"));

            let findRows = document.getElementsByClassName("addAnActionStep");
            let rowCount = 0;
            for (let row of findRows) {
                let element = <HTMLButtonElement>row;
                if (rowCount < parseInt(element.dataset.buttonrow)) {
                    rowCount = parseInt(element.dataset.buttonrow);
                }
            }

            for (let i = 1; i <= value; i++) {
                rowCount++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/EvidenceBasedStrategy');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#evidenceBasedStrategy > tbody").append("<tr><td>" + xhr.responseText + "</td></tr>");

                        $('.multiSelectMeasurableGoals').MultiSelect();
                        _that.bindCreateActionPlans();
                        _that.bindAddAnActionStep();
                        new CustomAccordion("actionPlanActionPlanAccordion" + rowCount.toString());
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "New Evidence-Based Strategy Row Added", 'success', 3000, null);
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount + "&planFK=" + planFK);
            }
        }
    }

    bindAddAnActionStep() {
        let addActionSteps = document.getElementsByClassName("addAnActionStep");
        for (let action of addActionSteps) {
            let addActionStep = <HTMLButtonElement>action;
            if (addActionStep.dataset.listener === "true") {

            } else {
                let buttonRow = parseInt(addActionStep.dataset.buttonrow);
                addActionStep.addEventListener("click", (e: Event) => this.addActionStep(buttonRow))
                addActionStep.dataset.listener = "true";
            }
        }
    }

    bindCreateActionPlans() {
        let actionPlanButtons = document.getElementsByClassName("createActionPlan");

        for (let button of actionPlanButtons) {
            let but = <HTMLButtonElement>button;
            if (but.dataset.listener === "true") {

            } else {
                but.addEventListener("click", (e: Event) => this.addActionPlan((<HTMLButtonElement>e.srcElement)));
                but.dataset.listener = "true";
            }
        }
    }

    addActionPlan(button: HTMLButtonElement) {
        let core = new Core();
        //Make sure there is a title in the Evidence Based-Strategy name
        let _that = this;
        let dontDoIt: boolean = false;
        let row = button.dataset.row;
        let textElement = document.getElementsByClassName("evidenceBasedStrategyName");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
            }
        }

        let selectedSequence = this.itemsSelected(row);
        if (selectedSequence.length === 0) {
            dontDoIt = true;
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.row;
            let textBoxId = "actionPlanEvidenceBasedStrategyName" + rowNumber.toString();
            let evidenceNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "Action Plan for: " + evidenceNameElement.value;
            let accordionParent = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.row == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            evidenceNameElement.readOnly = true;

            //Now show the accordion.
            let actionPlanElements = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var actionPlanElement: HTMLDivElement;

            for (let ap of actionPlanElements) {
                let ape = <HTMLDivElement>ap;
                if (ape.dataset.row === rowNumber) {
                    actionPlanElement = ape;
                }
            }
            //Get the measurable goals list to display inside the accordion
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/MeasurableGoalsList', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let element = document.getElementById("actionPlanActionPlanSection" + rowNumber);
                    let divElement = <HTMLDivElement>element;
                    let measurableGoals = divElement.querySelector(".actionPlanMeasurableGoalsReaderList");
                    let measurableGoalsElement = <HTMLUListElement>measurableGoals;

                    $(measurableGoalsElement).append(xhr.responseText);

                    let allMeasurableGoals = divElement.querySelectorAll(".measurableGoalModal");
                    for (let goal of allMeasurableGoals) {
                        let goalElement = <HTMLAnchorElement>goal;
                        let modalValue: string = goalElement.dataset.measurablegoal;
                        goal.addEventListener("click", (e: Event) => _that.showGoalModal(modalValue));
                    }
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "New Action Plan Row Added", 'success', 3000, null);
                } else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(selectedSequence));

            actionPlanElement.classList.remove("hide-action-plan");
            actionPlanElement.classList.add("open");

            button.classList.add("hide-action-plan");
        } else {
            //TODO:
            console.log("No entry");
        }
    }

    bindMeasurableGoals() {
        let allMeasurableGoals = document.querySelectorAll(".measurableGoalModal");
        for (let goal of allMeasurableGoals) {
            let goalElement = <HTMLAnchorElement>goal;
            let modalValue: string = goalElement.dataset.measurablegoal;
            goal.addEventListener("click", (e: Event) => this.showGoalModal(modalValue));
        }
    }

    showGoalModal(modalText: string) {
        let element = <HTMLDivElement>document.getElementById("actionPlanMeasurableGoalsDescription");

        element.innerHTML = modalText;

        let modal = new Modal('ActionPlanMeasurableGoalDescriptionModal', null);
        modal.show();
    }

    itemsSelected(row: string): string[] {
        let selectElements = document.getElementsByClassName("multiSelectMeasurableGoals");
        let elementSequence = [];

        for (let selectElement of selectElements) {
            let element = <HTMLDivElement>selectElement;

            if (element.dataset.row === row) {
                let selection = element.querySelectorAll("ul li.selected");
                if (selection.length > 0) {
                    for (let s of selection) {
                        let sElement = <HTMLLIElement>s;
                        let nextElement = sElement.querySelector("input");
                        let inputElement = <HTMLElement>nextElement;
                        elementSequence.push(inputElement.dataset.planpropertypk);
                    }
                }
            }
        }

        return elementSequence;
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("strategyWord");
        let num = <HTMLInputElement>document.getElementById("addMoreEvidenceBasedStrategiesNumber")
        let newWord = "strategies";
        if (parseInt(num.value) === 1) {
            newWord = "strategy";
        }

        word.textContent = newWord;
    }
}

//ProfessionalDevelopment
class ProfessionalDevelopment {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = [
            "professionalDevelopmentField",
            "addDanielsonFrameworkToListItem",
            "professionalDevelopmentLearningFormatStateRequired",
            "professionalDevelopmentFrequencyField"
        ];

        let saveButton = document.getElementById("professionalDevelopmentSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let addMoreRowsButton = document.getElementById("addProfessionalDevelopmentRows");
        if(addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e: Event) => this.addMoreProfessionalDevelopmentRows());

        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];

        let allElements = document.getElementsByClassName("professionalDevelopmentField");

        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                let toDelete = (htmlElement.value === "");

                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== null)
                allPDActivities.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== null)
                allLearningFormats.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== null)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== null)
                learningFormatOthers.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== null)
                learningFormatStateRequired.push(saveItem);
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== null)
                learningFormatFrameworks.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== null)
                learningFormatFrequency.push(saveItem);
            else
                if (saveItem !== null)
                    allOthers.push(saveItem);
        }

        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveProfessionalDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.LearningFormatDanielsonFrameworks.length === 0 && saveElement.LearningFormatFrequency.length === 0 && saveElement.LearningFormatOther.length === 0 && saveElement.LearningFormats.length === 0 && saveElement.LearningFormatStateRequired.length === 0 && saveElement.Others.length === 0 && saveElement.PDActivities.length === 0) {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAdd");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.srcElement))
                element.dataset.listener = "true";
            }
        }
    }

    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAdd");

        for (let danielsonButton of danielsonButtons) {
            let element = <HTMLButtonElement>danielsonButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addDanielsonFrameworkToList(<HTMLButtonElement>e.srcElement))
                element.dataset.listener = "true";
            }
        }
    }

    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivity");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.srcElement))
                element.addEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.srcElement))
                element.dataset.listener = "true";
            //}
        }
    }

    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStep");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
                element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
                element.dataset.listener = "true";
            //}
        }
    }

    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFramework");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
                element.addEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
                element.dataset.listener = "true";
            //}
        }
    }

    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormat");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addLearningFormat(<HTMLButtonElement>e.srcElement));
                element.dataset.listener = "true";
            }
        }
    }

    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e: Event) => this.removeLearningFormat(e));
                element.addEventListener("click", (e: Event) => this.removeLearningFormat(e));
                element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteActivityButtons();

    }

    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }
    addActionStepToActivity(button: HTMLButtonElement) {
        let core = new Core();
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = <HTMLSelectElement>document.getElementsByClassName("professionalDevelopmentAddActionStep");

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            if (element.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(element.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    addDanielsonFrameworkToList(button: HTMLButtonElement) {
        let core = new Core();
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = <HTMLSelectElement>document.getElementsByClassName("professionalDevelopmentAddDanielsonFramework");

        let lookupCodePK: number;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            if (element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                lookupCodePK = parseInt(element.value);
            }
        }
        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }
        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddDanielsonFrameworkToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToList[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                } else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        }
    }

    removeDanielsonFrameworkList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let lookupCodePK = element.dataset.lookupcodepk;

        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    addLearningFormat(button: HTMLButtonElement) {
        let core = new Core();
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivity");
        let _that = this;
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddLearningFormat');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormats[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
            } else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    removeLearningFormat(e: Event) {
        e.stopPropagation();
        let core = new Core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;
        let count = 0;

        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormat");
        for (let learningFormat of allLearningFormats) {
            let thisElement = <HTMLDivElement>learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteActivityButtons();
        core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }

    createNewPDActivity(button: HTMLButtonElement) {
        let _that = this;
        let dontDoIt: boolean = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "professionalDevelopmentActivity" + rowNumber.toString();
            let professionalDevelopmentNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;

            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordion");
            var professionalDevelopmentElement: HTMLDivElement;

            for (let ap of professionalDevelopmentElements) {
                let pde = <HTMLDivElement>ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }

            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
        }
    }

    addMoreProfessionalDevelopmentRows() {
        let core = new Core();
        let _that = this;
        let howManyElement = <HTMLInputElement>document.getElementById("addProfessionalDevelopmentRowsNumber");
        let howMany = parseInt(howManyElement.value);
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow ++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContent").append(xhr.responseText);
                        new CustomAccordion("professionalDevelopmentAccordion" + currentRow.toString());
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    } else {
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
}

//Communications
class Communications {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = [
            "communicationsField",
            "communicationsFrequencyField",
            "actionStepList",
            "communicationsField"
        ];

        let saveButton = document.getElementById("communicationsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let addMoreRowsButton = document.getElementById("addCommunicationsRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e: Event) => this.addMoreCommunicationsRows());

        let numberTextBox = document.getElementById("addCommunicationsRowsNumber");
        if (numberTextBox !== null)
            numberTextBox.addEventListener("change", (e: Event) => this.checkForPlural());

        this.bindCreateCommunicationActivityButtons();
        this.bindCommunicationsActionStepAddButtons();
        this.bindCommunicationsActionStepRemoveButtons();
        this.bindAddCommunicationButtons();
        this.bindRemoveCommunicationButtons();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("communicationsForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allCommActivities = [];
        let allCommunications = [];
        let allActionSteps = [];
        let allOthers = [];
        let communicationOthers = [];
        let communicationFrequency = [];

        let allElements = document.getElementsByClassName("communicationsField");

        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("communicationsCommunicationActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("communicationsFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("communicationsActivity") && saveItem !== null)
                allCommActivities.push(saveItem);
            else if (htmlElement.classList.contains("communicationsCommunicationActivity") && saveItem !== null)
                allCommunications.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== null)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== null)
                communicationOthers.push(saveItem);
            else if (htmlElement.classList.contains("communicationsFrequencyField") && saveItem !== null)
                communicationFrequency.push(saveItem);
            else
                if (saveItem !== null)
                    allOthers.push(saveItem);
        }

        let saveElement = {
            "CommActivities": allCommActivities,
            "Communications": allCommunications,
            "ActionSteps": allActionSteps,
            "CommunicationsOther": communicationOthers,
            "CommunicationFrequency": communicationFrequency,
            "Others": allOthers
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveCommunications', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.CommActivities.length === 0 && saveElement.CommunicationFrequency.length === 0 && saveElement.Communications.length === 0 && saveElement.CommunicationsOther.length === 0 && saveElement.Others.length === 0) {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    bindCommunicationsActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("communicationsActionStepAdd");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.srcElement))
                element.dataset.listener = "true";
            }
        }
    }

    bindCreateCommunicationActivityButtons() {
        let activityButtons = document.getElementsByClassName("createCommunicationsActivity");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.createNewCommActivity(<HTMLButtonElement>e.srcElement));
            element.addEventListener("click", (e: Event) => this.createNewCommActivity(<HTMLButtonElement>e.srcElement));
            element.dataset.listener = "true";
            //}
        }

    }

    bindCommunicationsActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deleteCommAddedActionStep");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
            element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }


    bindAddCommunicationButtons() {
        let activityButtons = document.getElementsByClassName("communicationsAddCommunication");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addCommunication(<HTMLButtonElement>e.srcElement));
                element.dataset.listener = "true";
            }
        }
    }

    bindRemoveCommunicationButtons() {
        let activityButtons = document.getElementsByClassName("communicationsDeleteCommunicationRow");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeCommunication(e));
            element.addEventListener("click", (e: Event) => this.removeCommunication(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteCommunicationsButtons();
    }

    showHideDeleteCommunicationsButtons() {
        let activityButtons = document.getElementsByClassName("communicationsDeleteCommunicationRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }

    addActionStepToActivity(button: HTMLButtonElement) {
        let core = new Core();
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("communicationsForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = <HTMLSelectElement>document.getElementsByClassName("communicationsAddActionStep");

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            if (element.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(element.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddCommActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindCommunicationsActionStepRemoveButtons();
                    core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    addCommunication(button: HTMLButtonElement) {
        let core = new Core();
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let communicationList = document.getElementsByClassName("communicationsCommunicationActivity");
        let _that = this;
        let formElement = document.getElementById("communicationsForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of communicationList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddCommunication');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".communications[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindRemoveCommunicationButtons();
                core.createHTMLAlert("alertMessageDiv", "New Communications Activity Row Added", 'success', 3000, null);
            } else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    removeCommunication(e: Event) {
        e.stopPropagation();
        let core = new Core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let allCommunications = document.getElementsByClassName("communicationsCommunication");
        for (let communication of allCommunications) {
            let thisElement = <HTMLDivElement>communication;

            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteCommunicationsButtons();
        core.createHTMLAlert("alertMessageDiv", "Communication Removed", 'success', 3000, null);
    }

    createNewCommActivity(button: HTMLButtonElement) {
        let _that = this;
        let dontDoIt: boolean = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("communicationsActivity");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "communicationsActivity" + rowNumber.toString();
            let communicationsNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + communicationsNameElement.value;
            let accordionParent = document.getElementsByClassName("communicationsAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            communicationsNameElement.readOnly = true;

            //Now show the accordion.
            let communicationsElements = document.getElementsByClassName("communicationsAccordion");
            let communicationsElement: HTMLDivElement;

            for (let ap of communicationsElements) {
                let comm = <HTMLDivElement>ap;
                if (comm.dataset.buttonrow === rowNumber) {
                    communicationsElement = comm;
                }
            }

            communicationsElement.classList.remove("hide-communications");
            communicationsElement.classList.add("open");
            button.classList.add("hide-communications");
        }
    }

    addMoreCommunicationsRows() {
        let core = new Core();
        let _that = this;
        let howManyElement = <HTMLInputElement>document.getElementById("addCommunicationsRowsNumber");
        let howMany = parseInt(howManyElement.value);
        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("communicationsActivity");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/AddNewCommunicationsActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#communicationsContent").append(xhr.responseText);
                        new CustomAccordion("communicationsAccordion" + currentRow.toString());
                        _that.bindAddCommunicationButtons();
                        _that.bindCommunicationsActionStepAddButtons();
                        _that.bindCreateCommunicationActivityButtons();
                        _that.bindRemoveCommunicationButtons();
                        core.createHTMLAlert("alertMessageDiv", "New Communication Row Added", 'success', 3000, null);
                    } else {
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("pluralWord");
        let num = <HTMLInputElement>document.getElementById("addCommunicationsRowsNumber")
        let newWord = "activities";
        if (parseInt(num.value) === 1) {
            newWord = "activity";
        }

        word.textContent = newWord;
    }

}