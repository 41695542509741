class Core {7
    constructor() { 
    }

    a11yClick(e: KeyboardEvent): boolean {
        if (e.type === "keypress") {
            let code = e.which;
            if (code === 32 || code === 13) {
                return true;
            }
        }
        
        return false;
    }

    activateMenu(controls: Array<string>, section: string): void {
        //Clear the menu active classes
        let readyMenus = document.getElementById("readySection").querySelectorAll("li");

        for (let link of readyMenus) {
            if (link.classList.contains("active")) {
                link.classList.remove("active");
            }
        }

        let setMenus = document.getElementById("setSection").querySelectorAll("li");

        for (let link of setMenus) {
            if (link.classList.contains("active")) {
                link.classList.remove("active");
            }
        }

        let goMenus = document.getElementById("goSection").querySelectorAll("li");

        for (let link of goMenus) {
            if (link.classList.contains("active")) {
                link.classList.remove("active");
            }
        }

        for (let link of controls) {
            let parentId = link + "Parent";
            let element = document.getElementById(parentId);
            element.classList.add("active");
        }

        //Deal with the open/closed state of the sections
        let leftBar = document.getElementById("leftBarAccordionGroup");
        let topLevels = leftBar.getElementsByClassName("Accordion-trigger");
        for (let top of topLevels) {
            if (top.classList.contains("open")) {
                top.classList.remove("open");
            }
        }

        let innerTopLevels = leftBar.getElementsByClassName("Accordion-panel");
        for (let inner of innerTopLevels) {
            if (inner.classList.contains("open")) {
                inner.classList.remove("open");
            }
        }

        let sectionName = section + "Section";
        let accordionName = section + "Accordion";

        let sectionElement = document.getElementById(sectionName);
        sectionElement.classList.add("open");

        let accordionElement = document.getElementById(accordionName);
        accordionElement.classList.add("open");
    }

    insertAfter(el, referenceNode) {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
    }

    pageReload(fromSave: boolean = null, planFK: number = null) {
        if (fromSave !== null && fromSave === true) {
            let url = window.location.href;
            var alertDiv = document.getElementById("alertDiv");
            alertDiv.setAttribute("data-saved", "from-save");
            if (url.indexOf("fromSave") === -1) {
                url += "?fromSave=" + fromSave.toString();

                window.location.href = url;
            } else {
                window.location.reload();
            }
        } else {
            window.location.reload();
        }
    }

    createHTMLAlert(id, message, type, timeout, callback) {
        var alertDiv = document.getElementById("alertDiv");
        //alertDiv.setAttribute("data-saved", type);
        alertDiv.style.top = "10px";
        var alertContainerDiv = document.getElementById("alertContainerDiv");
        var alertBox = document.getElementById(id);
        alertBox.innerHTML = message;
        alertBox.style.display = "block";
        alertDiv.style.display = "block";

        //TODO: Make these classes instead of inline. Add and remove as needed.
        switch (type) {
            case 'alert':
            case 'notification':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#FFF';
                alertContainerDiv.style.borderColor = '#CCC';
                break;
            case 'warning':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#fadd84';
                alertContainerDiv.style.borderColor = '#FFC237';
                break;
            case 'error':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#f87676';
                alertContainerDiv.style.borderColor = 'darkred';
                alertBox.style.fontWeight = 'bold';

                break;
            case 'information':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#57B7E2';
                alertContainerDiv.style.borderColor = '#0B90C4';
                break;
            case 'success':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#acdeb2';
                alertContainerDiv.style.borderColor = '#50C24E';
                break;
            default:
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#FFF';
                alertContainerDiv.style.borderColor = '#CCC';
                break;
        }

        if (timeout) {
            setTimeout(function () {
                alertBox.style.display = "none";
                alertDiv.style.display = "none";
                alertBox.innerHTML = "";
                alertDiv.style.top = "0";
                if (callback && typeof (callback) === 'function') {
                    callback();
                }
            }, timeout);
        }
        else {
            if (callback && typeof (callback) === 'function') {
                callback();
            }
        }
    }

    doValidation(allClasses: string[]) {
        let showMessage = this.clientSideValidation(allClasses);
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        for (let thisClass of allClasses) {
            let allElements = document.getElementsByClassName(thisClass);

            for (let element of allElements) {
                let alreadyExists = false;
                let htmlElement = <HTMLElement>element;
                if (htmlElement.dataset.percent && htmlElement.dataset.percent !== "") {
                    if (htmlElement.dataset.percent === "1.00") {

                        if ((htmlElement.dataset.row && parseInt(htmlElement.dataset.row) > 0) || (htmlElement.dataset.rownumber && parseInt(htmlElement.dataset.rownumber) > 0) || (htmlElement.dataset.buttonrow && parseInt(htmlElement.dataset.buttonrow) > 0)) {
                            //check to see if there are other fields the same as this one on this page
                            for (let innerClass of htmlElement.classList) {
                                if (innerClass != thisClass) {
                                    var otherElements = document.getElementsByClassName(innerClass);
                                    for (let otherElement of otherElements) {
                                        let otherHtmlElement = <HTMLElement>otherElement;
                                        if (otherHtmlElement.classList.contains("missing-field")) {
                                            alreadyExists = true;
                                        } else {
                                            if (otherHtmlElement instanceof HTMLInputElement) {
                                                if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                                    //Check to see if a prior group of radio buttons has already been validated
                                                    let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                                    if (OtherInputElement.hasAttribute("name")) {
                                                        let radioGroupName = OtherInputElement.getAttribute("name");

                                                        let radios = document.getElementsByName(radioGroupName);
                                                        let radioIsChecked: boolean = false;
                                                        let isAlreadyValidated: boolean = false;

                                                        for (var i = 0, length = radios.length; i < length; i++) {
                                                            let radioElement = <HTMLInputElement>radios[i];
                                                            if (radioElement.checked) {
                                                                radioIsChecked = true;
                                                            }

                                                            if (radioElement.classList.contains("missing-field")) {
                                                                isAlreadyValidated = true;
                                                            }
                                                        }

                                                        if (isAlreadyValidated || radioIsChecked) {
                                                            alreadyExists = true;
                                                        }
                                                    }
                                                } else {
                                                    let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                                    if (OtherInputElement.value !== "") {
                                                        alreadyExists = true;
                                                    }
                                                }   
                                            } else if (otherHtmlElement instanceof HTMLSelectElement) {
                                                let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                                                if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                                    alreadyExists = true;
                                                }
                                            } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                                                let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                                                if (otherTextAreaElement.value !== "") {
                                                    alreadyExists = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (!alreadyExists || (htmlElement.dataset.forcerequired && htmlElement.dataset.forcerequired === "true")) {
                            if (!element.classList.contains("missing-field")) {
                                if (element instanceof HTMLInputElement) {
                                    let inputElement = <HTMLInputElement>element;

                                    //Only validate once for radio buttons
                                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                        if (inputElement.hasAttribute("name")) {
                                            let radioName = inputElement.getAttribute("name");

                                            let radioButtons = document.getElementsByName(radioName);
                                            let alreadyValidated: boolean = false;
                                            let isChecked: boolean = false;

                                            for (var i = 0, length = radioButtons.length; i < length; i++) {
                                                let radioElement = <HTMLInputElement>radioButtons[i];
                                                if (radioElement.classList.contains("missing-field")) {
                                                    alreadyValidated = true;
                                                }

                                                if (radioElement.checked) {
                                                    isChecked = true;
                                                }
                                            }

                                            if (!alreadyValidated && !isChecked) {
                                                inputElement.classList.add("missing-field");
                                                inputElement.setAttribute("aria-invalid", "true");
                                                this.createErrorLabelForInput(inputElement);
                                                showMessage = true;
                                                totalErrors++;
                                            }
                                        }
                                    } else if (inputElement.value === "") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        this.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (element instanceof HTMLSelectElement) {
                                    let selectElement = <HTMLSelectElement>element;
                                    if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                        selectElement.classList.add("missing-field");
                                        selectElement.setAttribute("aria-invalid", "true");
                                        this.createErrorLabelForInput(selectElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (element instanceof HTMLTextAreaElement) {
                                    let textAreaElement = <HTMLTextAreaElement>element;
                                    if (textAreaElement.value === "") {
                                        textAreaElement.classList.add("missing-field");
                                        textAreaElement.setAttribute("aria-invalid", "true");
                                        this.createErrorLabelForInput(textAreaElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }   
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    initializeRequiredFields(allClasses: string[]) {
        for (let thisClass of allClasses) {
            let allElements = document.getElementsByClassName(thisClass);

            for (let element of allElements) {
                let alreadyExists = false;
                let htmlElement = <HTMLElement>element;
                if (htmlElement.dataset.percent && htmlElement.dataset.percent !== "") {
                    if (htmlElement.dataset.percent === "1.00") {

                        if ((htmlElement.dataset.row && parseInt(htmlElement.dataset.row) > 0) || (htmlElement.dataset.rownumber && parseInt(htmlElement.dataset.rownumber) > 0) || (htmlElement.dataset.buttonrow && parseInt(htmlElement.dataset.buttonrow) > 0) || (htmlElement.dataset.buttonrow && parseInt(htmlElement.dataset.buttonrow) > 0)) {
                            //check to see if there are other fields the same as this one on this page
                            for (let innerClass of htmlElement.classList) {
                                if (innerClass != thisClass && innerClass != "missing-field") {
                                    var otherElements = document.getElementsByClassName(innerClass);
                                    for (let otherElement of otherElements) {
                                        let otherHtmlElement = <HTMLElement>otherElement;
                                        if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                                            alreadyExists = true;
                                        } 
                                    }
                                }
                            }
                        }

                        if (!alreadyExists) {

                            if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                                htmlElement.setAttribute("aria-required", "true");
                                let label = this.findLabelForInput(htmlElement);

                                if (label !== null && !label.classList.contains("isRequired")) {
                                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                                    label.classList.add("isRequired");
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    trapFocus(element: HTMLElement) {
        var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), img:not([disabled])');
        var firstFocusableEl = <HTMLElement>focusableEls[0];
        var lastFocusableEl = <HTMLElement>focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        element.addEventListener('keydown', function (e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }

        });
    }

    //Returns label for element (either labelled by or label for). Returns null if no label found
    findLabelForInput(element: HTMLElement) {

        if (element.hasAttribute("data-label") && element.getAttribute("data-label") !== "") {
            let dataLabel = element.getAttribute("data-label");
            let labelForEl = document.querySelector(`#${dataLabel}`);

            if (labelForEl !== null) {
                return labelForEl;
            }
        }

        if (element.hasAttribute("aria-labelledby")) {
            let labelledBy = element.getAttribute("aria-labelledby");
            let labelledByEl = document.querySelector(`#${labelledBy}`);

            if (labelledByEl !== null) {
                return labelledByEl;
            }    
        }

        if (element.hasAttribute("id") && element.getAttribute("id") !== "") {
            let label = document.querySelector(`label[for=${element.id}]`);

            if (label !== null) {
                return label;
            }
        }

        if (element.parentNode instanceof HTMLTableCellElement) {
            //Testing the cases where label is a <th> in the table.
            let parentElement = element.parentElement;
            let index = Array.prototype.indexOf.call(parentElement.parentNode.children, parentElement)
            let correspondingTable = element.closest("table");
            if (correspondingTable !== null) {
                let correspondingTh = correspondingTable.querySelector(`th:nth-child(${index + 1})`);
                if (correspondingTh !== null) {
                    return correspondingTh;
                }
            } 
        } 

        return null;
    }

    //Puts exclamation mark on labels for invalid fields
    createErrorLabelForInput(element: HTMLElement) {
        let label = this.findLabelForInput(element);
        if (label !== null && !label.classList.contains("hasBeenValidated")) {
            label.classList.add("hasBeenValidated");
            label.innerHTML = "<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> " + label.innerHTML;
        }
    }

    showLoader() {
        let loaderElement = document.getElementById("loader");
        if (loaderElement !== null) {
            let imageElement = document.getElementById("loaderImage");
            if (imageElement !== null) {
                imageElement.focus();
                this.trapFocus(imageElement);
            }

            loaderElement.classList.add("open");
        }
    }

    hideLoader() {
        let loaderElement = document.getElementById("loader");
        if (loaderElement !== null) {
            loaderElement.classList.remove("open");
        }
    }

    activateSubMenu(menuId: string) {
        let allMenus = document.getElementsByClassName("subMenuElement");
        for (let menu of allMenus) {
            if (menu.classList.contains("active")) {
                menu.classList.remove("active");
            }
        }

        let newActive = document.getElementById(menuId);
        newActive.classList.add("active");
    }
}