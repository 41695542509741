/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Graduation Pathways Profile
class GraduationPathwaysProfile {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["graduationPathwaysProfileField"];

        let graduationPathwaysProfileSaveButton = document.getElementById("graduationPathwaysProfileSave");
        if (graduationPathwaysProfileSaveButton !== null)
            graduationPathwaysProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("graduationPathwaysProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationPathwaysProfileField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationRequirements/SaveGraduationPathwaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Graduation Pathway
class GraduationPathway {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["graduationPathwayField", "graduationPathwayFieldCheckbox"];

        let graduationPathwaySaveButton = document.getElementById("graduationPathwaySave");
        if (graduationPathwaySaveButton !== null)
            graduationPathwaySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let proficiencyGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneProficiencyGraduated");
        if (proficiencyGraduatedField !== null) {
            proficiencyGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneProficiencyEnrollment", "graduationPathwayKeystoneProficiencyAverage"));
            this.calculatePercentage(proficiencyGraduatedField, "graduationPathwayKeystoneProficiencyEnrollment", "graduationPathwayKeystoneProficiencyAverage");
        }

        let compositeGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneCompositeGraduated");
        if (compositeGraduatedField !== null) {
            compositeGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneCompositeEnrollment", "graduationPathwayKeystoneCompositeAverage"));
            this.calculatePercentage(compositeGraduatedField, "graduationPathwayKeystoneCompositeEnrollment", "graduationPathwayKeystoneCompositeAverage");
        }

        let alternateAssessmentGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneAlternateAssessmentGraduated");
        if (alternateAssessmentGraduatedField !== null) {
            alternateAssessmentGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneAlternateAssessmentEnrollment", "graduationPathwayKeystoneAlternateAssessmentAverage"));
            this.calculatePercentage(alternateAssessmentGraduatedField, "graduationPathwayKeystoneAlternateAssessmentEnrollment", "graduationPathwayKeystoneAlternateAssessmentAverage");
        }

        let evidenceBasedGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneEvidenceBasedGraduated");
        if (evidenceBasedGraduatedField !== null) {
            evidenceBasedGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneEvidenceBasedEnrollment", "graduationPathwayKeystoneEvidenceBasedAverage"));
            this.calculatePercentage(evidenceBasedGraduatedField, "graduationPathwayKeystoneEvidenceBasedEnrollment", "graduationPathwayKeystoneEvidenceBasedAverage");
        }

        let cteGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneCTEGraduated");
        if (cteGraduatedField !== null) {
            cteGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneCTEEnrollment", "graduationPathwayKeystoneCTEAverage"));
            this.calculatePercentage(cteGraduatedField, "graduationPathwayKeystoneCTEEnrollment", "graduationPathwayKeystoneCTEAverage");
        }

        let iepGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneIEPGraduated");
        if (iepGraduatedField !== null) {
            iepGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneIEPEnrollment", "graduationPathwayKeystoneIEPAverage"));
            this.calculatePercentage(iepGraduatedField, "graduationPathwayKeystoneIEPEnrollment", "graduationPathwayKeystoneIEPAverage")
        }

        let overFivePercentGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayKeystoneOverFivePercentGraduated");
        if (overFivePercentGraduatedField !== null) {
            overFivePercentGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayKeystoneOverFivePercentEnrollment", "graduationPathwayKeystoneOverFivePercentAverage", true));
            this.calculatePercentage(overFivePercentGraduatedField, "graduationPathwayKeystoneOverFivePercentEnrollment", "graduationPathwayKeystoneOverFivePercentAverage", true)
        }

        let alternateAssessmentGridSelection = document.getElementsByClassName("graduationPathwayAlternateAssessmentsGrid");
        for (let gridElement of alternateAssessmentGridSelection) {
            gridElement.addEventListener("change", (e: Event) => this.selectAlternateAssessmentGridElement(<HTMLInputElement>e.srcElement));
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    selectAlternateAssessmentGridElement(e: HTMLInputElement) {
        let checkbox = e;
        if (checkbox.checked) {
            let td = checkbox.parentElement;
            let tr = td.parentElement;
            let clone = tr.cloneNode(true);

            let clonedLabel = (<HTMLTableRowElement>clone).querySelector("label");
            clonedLabel.htmlFor = `${clonedLabel.htmlFor}Clone`;

            let clonedCheckbox = (<HTMLTableRowElement>clone).querySelector("input");
            clonedCheckbox.id = `${clonedCheckbox.id}Clone`;
            clonedCheckbox.classList.add("graduationPathwayFieldCheckbox");

            let selectedTable = <HTMLTableElement>document.getElementById("alternateAssessmentSelections");
            selectedTable.querySelector("tbody").appendChild(clone);
        } else {
            let clonedId = `${checkbox.id}Clone`;
            let selectedTable = <HTMLTableElement>document.getElementById("alternateAssessmentSelections");
            if (selectedTable != null) {
                let clonedElement = <HTMLInputElement>selectedTable.querySelector(`#${clonedId}`);
                if (clonedElement != null) {
                    let clonedTD = clonedElement.parentElement;
                    let clonedTR = clonedTD.parentElement;
                    clonedTR.remove();
                }
            }
        }
    }

    calculatePercentage(gradInput: HTMLInputElement, enrollmentElement: string, averageElement: string, overFive: boolean = false) {
        let gradValue = gradInput.value;
        let gradValueNum = Number(gradValue);
        if (!isNaN(gradValueNum)) {
            let enrollmentInput = <HTMLInputElement>document.getElementById(enrollmentElement);
            if (enrollmentInput !== null) {
                let enrollmentValue = enrollmentInput.value;
                let average = (parseInt(gradValue) / parseInt(enrollmentValue)) * 100;

                let averageInput = <HTMLInputElement>document.getElementById(averageElement);
                if (averageInput !== null)
                    averageInput.value = average.toFixed(2).toString();
            }

            if (overFive) {
                let averageInput = <HTMLInputElement>document.getElementById(averageElement);
                let divElement = document.getElementById("overFivePercent");
                if (parseFloat(averageInput.value) > 5.0) {
                    divElement.classList.add("show");
                } else {
                    divElement.classList.remove("show");
                }
            }
        }
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let allCheckboxSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("graduationPathwayForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationPathwayField");
        let checkboxInputs = document.getElementsByClassName("graduationPathwayFieldCheckbox");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.datapointpropertypk);
            let dataPointPlanPropertyPK = 0;
            let hadValue = false;
            let lookupCodePK: number = parseInt(element.dataset.lookupcodepk);
            dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: lookupCodePK,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK
                };

                allCheckboxSaveElements.push(saveItem);
            }
        }

        let graduationPathwaySaveModel = {
            "TextElements": allSaveElements,
            "CheckboxElements": allCheckboxSaveElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationRequirements/SaveGraduationPathway', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(graduationPathwaySaveModel));
        }
    }
}

//Graduation Pathway Percentages
class GraduationPathwayPercentages {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["graduationPathwayPercentagesRadioField", "graduationPathwayPercentagesField"];

        let graduationPathwayPercentagesSaveButton = document.getElementById("graduationPathwayPercentagesSave");
        if (graduationPathwayPercentagesSaveButton !== null)
            graduationPathwayPercentagesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let percentagesGraduatedField = <HTMLInputElement>document.getElementById("graduationPathwayPercentagesDidNotGraduateNumber");
        if (percentagesGraduatedField !== null) {
            percentagesGraduatedField.addEventListener("input", (e: Event) => this.calculatePercentage(<HTMLInputElement>e.srcElement, "graduationPathwayPercentagesGrade12Enrollment", "graduationPathwayPercentagesNonGraduatedAverage"));
            this.calculatePercentage(percentagesGraduatedField, "graduationPathwayPercentagesGrade12Enrollment", "graduationPathwayPercentagesNonGraduatedAverage");
        }

        let percentagesDidNotGraduateSelection = document.getElementsByClassName("graduationPathwayPercentagesRadioField");
        for (let percentagesDidNotGraduate of percentagesDidNotGraduateSelection) {
            percentagesDidNotGraduate.addEventListener("change", (e: Event) => this.shouldShowEnrollment(e));
        }

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    shouldShowEnrollment(e: Event) {
        let containerElement = <HTMLDivElement>document.getElementById("graduationPathwayPercentagesNonGraduatedContainer");
        if ((<HTMLInputElement>e.srcElement).labels[0].textContent.toLowerCase() === "yes") {
            containerElement.classList.add("show");
        } else {
            containerElement.classList.remove("show");
        }
    }

    calculatePercentage(gradInput: HTMLInputElement, enrollmentElement: string, averageElement: string, overFive: boolean = false) {
        let gradValue = gradInput.value;
        let gradValueNum = Number(gradValue);
        if (!isNaN(gradValueNum)) {
            let enrollmentInput = <HTMLInputElement>document.getElementById(enrollmentElement);
            if (enrollmentInput !== null) {
                let enrollmentValue = enrollmentInput.value;
                let average = (parseInt(gradValue) / parseInt(enrollmentValue)) * 100;

                let averageInput = <HTMLInputElement>document.getElementById(averageElement);
                if (averageInput !== null)
                    averageInput.value = average.toFixed(2).toString();
            }

            if (overFive) {
                let averageInput = <HTMLInputElement>document.getElementById(averageElement);
                let divElement = document.getElementById("overFivePercent");
                if (parseFloat(averageInput.value) > 5.0) {
                    divElement.classList.add("show");
                } else {
                    divElement.classList.remove("show");
                }
            }
        }
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("graduationPathwayPercentagesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationPathwayPercentagesField");
        let radioInputs = document.getElementsByClassName("graduationPathwayPercentagesRadioField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationRequirements/SaveGraduationPathwayPercentages', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Signatures
class Signatures {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["graduationSignaturesRadioField", "graduationSignaturesField"];

        let signaturesSaveButton = document.getElementById("signaturesSave");
        if (signaturesSaveButton !== null)
            signaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let fileUploader = document.getElementById("graduationSignaturesPolicy");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    uploadFile(e: HTMLInputElement) {
        let core: Core = new Core();
        core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("graduationSignaturesPolicyForm");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationRequirements/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("graduationSignaturesPolicy");
                        formFile.value = "";
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("signaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("graduationSignaturesField");
        let radioInputs = document.getElementsByClassName("graduationSignaturesRadioField");

        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationRequirements/SaveGraduationSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Curriculum Summary Checklist and Submission
class GraduationRequirementsSummaryChecklistAndSubmission {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core: Core = new Core();
            core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/GraduationRequirements/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}