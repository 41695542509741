/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Profile and Plan Essentials
class ProfessionalDevelopmentProfileAndPlanEssentials {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["act48ProfileField"];

        let act48ProfileSaveButton = document.getElementById("act48ProfileSave");
        if (act48ProfileSaveButton !== null)
            act48ProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("act48ProfileForm");
        let inputs = document.getElementsByClassName("act48ProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false,
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveAct48Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Steering Committee
class ProfessionalDevelopmentSteeringCommittee {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["steeringCommitteeField"];

        this.bindDeleteRowButtons();

        let numberTextBox = document.getElementById("steeringCommitteeAddRowsNumber");
        if (numberTextBox !== null)
            numberTextBox.addEventListener("change", (e: Event) => this.checkForPlural());

        let deleteSteeringCommitteeConfirmButton = document.getElementById("pdReportSteeringCommitteeDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let deleteSteeringCommitteeCancelButton = document.getElementById("pdReportSteeringCommitteeDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let steeringCommitteeAddRowsButton = document.getElementById("steeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e: Event) => this.addNewSteeringCommitteeRow());

        let steeringCommitteeSave = document.getElementById("saveCommitteeMembers");
        if (steeringCommitteeSave !== null)
            steeringCommitteeSave.addEventListener("click", (e: Event) => this.save("save"));

        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e: Event) => this.checkForPlural());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let planPK = 0;

        let inputs = document.getElementsByClassName("steeringCommitteeField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);

            if (ele.classList.contains("steeringCommitteeChosenAppointedBy")) {
                let element = <HTMLSelectElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } else {
                let element = <HTMLInputElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } 
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = <HTMLButtonElement>deleteButton;

            deleteButtonElement.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("profDevAct48DeleteSteeringCommitteeRow", "#pdReportSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core: Core = new Core();
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        core.showLoader();

        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
                    modal.hide();

                    core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();

                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
                    modal.hide();
                    core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    addNewSteeringCommitteeRow() {
        let core = new Core();
        let newRow: number = 0;
        let _that = this;
        let formData = document.getElementById("steeringCommittee");
        let planFK = parseInt(formData.dataset.planpk);
        let numberOfRowsElement = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);

        if (numberOfRows !== null && numberOfRows > 0) {

            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = <HTMLInputElement>comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteRowButtons();
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planFK=" + planFK);
            }
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }
}

//Professional Education Plan
class ProfessionalDevelopmentProfessionalEducationPlan {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["professionalDevelopmentEdPlanField"];

        let saveButton = document.getElementById("saveProfessionalEducationPlan");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let exportToWordButton = document.getElementById("professionalEdPlanExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = document.getElementById("professionalEdPlanExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let planPK = 0;

        let inputs = document.getElementsByClassName("professionalDevelopmentEdPlanField");
        let professionalEdPlanElement = document.getElementById("professionalEducationPlan");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            if (element.checked) {
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: 0,
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalEducationPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {
        console.log("Export to Word");
    }

    exportToExcel() {
        console.log("Export to Excel");
    }
}

//Action Plans Steps from Comprehensive Plan
class ProfessionalDevelopmentActionPlansStepsFromComprehensivePlan {

    constructor() {
        let core = new Core();

        let exportToWordButton = document.getElementById("act48professionalDevelopmentExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord());

        let exportToExcelButton = document.getElementById("act48professionalDevelopmentExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            core.hideLoader();
    }
}

//Other Professional Development Activities
class OtherProfessionalDevelopmentActivies {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = [
            "professionalDevelopmentField",
            "addDanielsonFrameworkToListItem",
            "professionalDevelopmentLearningFormatStateRequired",
            "professionalDevelopmentFrequencyField"
        ];

        let saveButton = document.getElementById("act48OtherProfessionalDevelopmentSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let addMoreRowsButton = document.getElementById("addAct48OtherProfessionalDevelopmentRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e: Event) => this.addMoreProfessionalDevelopmentRows());

        let exportToWordButton = document.getElementById("act48OtherProfessionalDevelopmentExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = document.getElementById("act48OtherProfessionalDevelopmentExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);

        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];

        let allElements = document.getElementsByClassName("professionalDevelopmentField");

        for (let element of allElements) {
            let planPropertyPK: number = 0;
            let dataPointPlanPropertyPK: number = 0;
            let buttonRow: number = 0;

            let htmlElement;
            let saveItem: IPlanPropertyExtended;

            if (element.classList.contains("actionStepList")) {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;


                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            } else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = <HTMLSelectElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            } else {
                htmlElement = <HTMLInputElement>element;
                let rowNumber: number = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;

                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;

                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }

            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== null)
                allPDActivities.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== null)
                allLearningFormats.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== null)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== null)
                learningFormatOthers.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== null)
                learningFormatStateRequired.push(saveItem);
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== null)
                learningFormatFrameworks.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== null)
                learningFormatFrequency.push(saveItem);
            else
                if (saveItem !== null)
                    allOthers.push(saveItem);
        }

        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.LearningFormatDanielsonFrameworks.length === 0 && saveElement.LearningFormatFrequency.length === 0 && saveElement.LearningFormatOther.length === 0 && saveElement.LearningFormats.length === 0 && saveElement.LearningFormatStateRequired.length === 0 && saveElement.Others.length === 0 && saveElement.PDActivities.length === 0) {
            if (referrer === "save") {
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeLearningFormat(e));
            element.addEventListener("click", (e: Event) => this.removeLearningFormat(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteActivityButtons();
    }

    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = <HTMLButtonElement>activityButton;
                for (let activityButton of activityButtons) {
                    let element = <HTMLButtonElement>activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }

    removeLearningFormat(e: Event) {
        e.stopPropagation();
        let core = new Core;
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;
        let count = 0;

        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormat");
        for (let learningFormat of allLearningFormats) {
            let thisElement = <HTMLDivElement>learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteActivityButtons();
        core.createHTMLAlert("alertMessageDiv", "Communications Activity Removed", 'success', 3000, null);
    }

    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFramework");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
            element.addEventListener("click", (e: Event) => this.removeDanielsonFrameworkList(e));
            element.dataset.listener = "true";
            //}
        }
    }

    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStep");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e: Event) => this.removeItemFromActionStepList(e))
            element.addEventListener("click", (e: Event) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }

    removeItemFromActionStepList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;

        let planPropertyPK = element.dataset.planpropertypk;

        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = <HTMLDivElement>actionStep;

            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormat");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addLearningFormat(<HTMLButtonElement>e.srcElement));
                element.dataset.listener = "true";
            }
        }
    }

    addLearningFormat(button: HTMLButtonElement) {
        let core = new Core();
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow: number = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivity");
        let _that = this;
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = <HTMLSelectElement>activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/AddLearningFormat');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormats[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
            } else {
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }

    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivity");

        for (let activityButton of activityButtons) {
            let element = <HTMLButtonElement>activityButton;

            element.removeEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.srcElement))
            element.addEventListener("click", (e: Event) => this.createNewPDActivity(<HTMLButtonElement>e.srcElement))
            element.dataset.listener = "true";
        }
    }

    createNewPDActivity(button: HTMLButtonElement) {
        let _that = this;
        let dontDoIt: boolean = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let tElement of textElement) {
            let properElement = <HTMLInputElement>tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
            }
        }

        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "professionalDevelopmentActivity" + rowNumber.toString();
            let professionalDevelopmentNameElement = <HTMLInputElement>document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordion");
            var thisElement: HTMLDivElement;

            for (let accordion of accordionParent) {
                let acc = <HTMLDivElement>accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }

            let theSpan = <HTMLSpanElement>thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;

            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordion");
            var professionalDevelopmentElement: HTMLDivElement;

            for (let ap of professionalDevelopmentElements) {
                let pde = <HTMLDivElement>ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }

            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
        }
    }

    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAdd");

        for (let actionStepButton of actionStepButtons) {
            let element = <HTMLButtonElement>actionStepButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addActionStepToActivity(<HTMLButtonElement>e.srcElement))
                element.dataset.listener = "true";
            }
        }
    }

    addActionStepToActivity(button: HTMLButtonElement) {
        let core = new Core();
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = <HTMLSelectElement>document.getElementsByClassName("professionalDevelopmentAddActionStep");

        let actionStepPlanPropertyPK: number;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            if (element.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(element.value);
            }
        }

        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }

        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                } else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
    }

    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAdd");

        for (let danielsonButton of danielsonButtons) {
            let element = <HTMLButtonElement>danielsonButton;

            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e: Event) => this.addDanielsonFrameworkToList(<HTMLButtonElement>e.srcElement))
                element.dataset.listener = "true";
            }
        }
    }

    addDanielsonFrameworkToList(button: HTMLButtonElement) {
        let core = new Core();
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = <HTMLSelectElement>document.getElementsByClassName("professionalDevelopmentAddDanielsonFramework");

        let lookupCodePK: number;
        for (let selectElement of selectElements) {
            let element = <HTMLSelectElement>selectElement;
            if (element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                lookupCodePK = parseInt(element.value);
            }
        }
        let isNotInList: boolean = true;

        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let listElement of allListElements) {
            let element = <HTMLDivElement>listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }
        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddDanielsonFrameworkToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToList[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                } else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        }
    }

    removeDanielsonFrameworkList(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let rowNumber: string = element.dataset.row;
        let buttonrow: string = element.dataset.buttonrow;

        let lookupCodePK = element.dataset.lookupcodepk;

        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    addMoreProfessionalDevelopmentRows() {
        let core = new Core();
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;

        let activityElements = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activityElements) {
            let activityElement = <HTMLInputElement>activity;

            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }

        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContent").append(xhr.responseText);
                        new CustomAccordion("professionalDevelopmentAccordion" + currentRow.toString());
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    } else {
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
}

//Evaluation and Review
class EvaluationAndReview {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["evaluationAndReviewField"];

        let evaluationAndReviewSaveButton = document.getElementById("act48EvaluationAndReviewSave");
        if (evaluationAndReviewSaveButton !== null)
            evaluationAndReviewSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let evaluationAndReviewExportToWordButton = document.getElementById("act48EvaluationAndReviewExportToWord");
        if (evaluationAndReviewExportToWordButton !== null)
            evaluationAndReviewExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let evaluationAndReviewExportToExcelButton = document.getElementById("act48EvaluationAndReviewExportToExcel");
        if (evaluationAndReviewExportToExcelButton !== null)
            evaluationAndReviewExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let planPK = 0;
        let formElement = document.getElementById("evaluationAndReviewForm");
        planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("evaluationAndReviewField");

        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLTextAreaElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveEvaluationAndReview', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

//Professional Education Plan Assurances
class ProfessionalEducationPlanAssurances {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["professionalEducationPlanAssurancesField"];

        let professionalEducationPlanSaveButton = document.getElementById("act48ProfessionalEducationPlanAssurancesSave");
        if (professionalEducationPlanSaveButton !== null)
            professionalEducationPlanSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let professionalEducationPlanExportToWordButton = document.getElementById("act48ProfessionalEducationPlanAssurancesExportToWord");
        if (professionalEducationPlanExportToWordButton !== null)
            professionalEducationPlanExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let fileUploader = document.getElementById("uploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        let professionalEducationExportToExcelButton = document.getElementById("act48ProfessionalEducationPlanAssurancesExportToExcel");
        if (professionalEducationExportToExcelButton !== null)
            professionalEducationExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    uploadFile(e: HTMLInputElement) {
        let core: Core = new Core();
        core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("uploadMinutesFile");
                        formFile.value = "";
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let planPK = 0;
        let formElement = document.getElementById("professionalEducationPlanAssurancesForm");
        planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("professionalEducationPlanAssurancesField");

        for (let ele of inputs) {
            let planPropertyPK = 0;

            let element = <HTMLTextAreaElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalEducationPlanAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

//Curriculum Summary Checklist and Submission
class ProfessionalDevelopmentSummaryChecklistAndSubmission {
    constructor() {
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core: Core = new Core();
            core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}