/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Induction Plan Profile
class InductionPlanProfile {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["inductionPlanProfileField"];

        let inductionPlanProfileSaveButton = document.getElementById("inductionPlanProfileSave");
        if (inductionPlanProfileSaveButton !== null)
            inductionPlanProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("inductionPlanProfileForm");
        let inputs = document.getElementsByClassName("inductionPlanProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Teacher Induction Committee Participants
class TeacherInductionCommitteeParticipants {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.bindDeleteButtons();
        this.validationClasses = ["steeringCommitteeField"];

        let exportToWordButton = <HTMLButtonElement>document.getElementById("inductionExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = <HTMLButtonElement>document.getElementById("inductionExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let saveInductionCommitteeMembers = <HTMLButtonElement>document.getElementById("saveInductionCommitteeMembers");
        if (saveInductionCommitteeMembers !== null)
            saveInductionCommitteeMembers.addEventListener("click", (e: Event) => this.save("save"));

        let inductionPlanSteeringCommitteeDeleteCancel = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteCancel");
        if (inductionPlanSteeringCommitteeDeleteCancel !== null)
            inductionPlanSteeringCommitteeDeleteCancel.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let inductionPlanSteeringCommitteeDeleteConfirm = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteConfirm");
        if (inductionPlanSteeringCommitteeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeDeleteConfirm.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let steeringCommitteeAddRowsButton = document.getElementById("inductionSteeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e: Event) => this.addNewSteeringCommitteeRow());

        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e: Event) => this.checkForPlural());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }

        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }

        let planPK = 0;

        let inputs = document.getElementsByClassName("steeringCommitteeField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);

            if (ele.classList.contains("steeringCommitteeChosenAppointedBy")) {
                let element = <HTMLSelectElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } else {
                let element = <HTMLInputElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeInductionSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core: Core = new Core();
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        core.showLoader();

        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();

                    core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();

                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    addNewSteeringCommitteeRow() {
        let core = new Core();
        let newRow: number = 0;
        let _that = this;
        let numberOfRowsElement = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);

        if (numberOfRows !== null && numberOfRows > 0) {

            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = <HTMLInputElement>comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteButtons();
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow);
            }
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }
}

// Educator Induction Plan
class EducatorInductionPlan {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["inductionPlanEducatorInductionPlan"];

        let educatorInductionPlanSaveButton = document.getElementById("saveEducatorInductionPlan");
        if (educatorInductionPlanSaveButton !== null)
            educatorInductionPlanSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorInductionPlanExportToWordButton = document.getElementById("exportToWordEducatorInductionPlan");
        if (educatorInductionPlanExportToWordButton !== null)
            educatorInductionPlanExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let educatorInductionPlanExportToExcelButton = document.getElementById("exportToExcelEducatorInductionPlan");
        if (educatorInductionPlanExportToExcelButton !== null)
            educatorInductionPlanExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanEducatorInductionPlanForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("inductionPlanEducatorInductionPlan");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Mentors
class Mentors{

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["indctionPlanMentorRadioField", "inductionPlanMentorField"];

        let mentorsSaveButton = document.getElementById("saveMentors");
        if (mentorsSaveButton !== null)
            mentorsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let mentorsExportToWordButton = document.getElementById("exportToWordMentors");
        if (mentorsExportToWordButton !== null)
            mentorsExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let mentorsExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (mentorsExportToExcelButton !== null)
            mentorsExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanMentorsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanMentorField");
        let inputs = document.getElementsByClassName("indctionPlanMentorRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveMentors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Needs Assessment
class NeedsAssessment {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["inductionPlanNeedsAssessmentRadioField", "inductionPlanNeedsAssessmentField"];

        let needsAssessmentSaveButton = document.getElementById("saveNeedsAssessment");
        if (needsAssessmentSaveButton !== null)
            needsAssessmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let needsAssessmentExportToWordButton = document.getElementById("exportToWordMentors");
        if (needsAssessmentExportToWordButton !== null)
            needsAssessmentExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let needsAssessmentExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (needsAssessmentExportToExcelButton !== null)
            needsAssessmentExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanNeedsAssessmentForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanNeedsAssessmentField");
        let inputs = document.getElementsByClassName("inductionPlanNeedsAssessmentRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveNeedsAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Educator Induction Plan Topic Areas
class EducatorInductionPlanTopicAreas {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["inductionPlanTopicAreaTimelineSelect"];

        let educatorInductionPlanTopicAreasSaveButton = document.getElementById("saveEducatorInductionPlanTopicAreas");
        if (educatorInductionPlanTopicAreasSaveButton !== null)
            educatorInductionPlanTopicAreasSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let reportTopicAreasButton = document.getElementById("inductionReportSelectedTopics");
        if (reportTopicAreasButton !== null)
            reportTopicAreasButton.addEventListener("click", (e: Event) => this.reportTopicAreas());

        let topicAreaExportToWordButton = document.getElementById("exportToWordTopicAreas");
        if (topicAreaExportToWordButton !== null)
            topicAreaExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let topicAreaExportToExcelButton = document.getElementById("exportToExcelTopicAreas");
        if (topicAreaExportToExcelButton !== null)
            topicAreaExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let allAddDanielsonButtons = document.getElementsByClassName("addThisDanielson");
        for (let addDanielsonButton of allAddDanielsonButtons)
            addDanielsonButton.addEventListener("click", (e: Event) => this.addDanielson(e));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
        this.bindRemoveDanielsonButtons();
    }

    addDanielson(e: Event) {
        let core = new Core();
        let _that = this;
        let lookupCodePK = parseInt((<HTMLButtonElement>e.srcElement).dataset.lookupcodepk);
        let danielsonSelection = <HTMLSelectElement>document.querySelector(`.danielsonFrameworkList[data-lookupcodepk="${lookupCodePK}"]`);
        let propertyPK = danielsonSelection.dataset.propertypk;
        let danielsonLookupCodePK = danielsonSelection.value;

        if (danielsonLookupCodePK === "0") {
            core.createHTMLAlert("alertMessageDiv", "Please select a Danielson Framework to add", "warning", 3000, null);
        } else {
            let isNotInList: boolean = true;
            let allListElements = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
            for (let listElement of allListElements) {
                let element = <HTMLDivElement>listElement;
                if (element.dataset.danielsonlookupcodepk === danielsonLookupCodePK.toString()) {
                    isNotInList = false;
                }
            }

            if (isNotInList) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddDanielsonFrameworkToList');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $(".addDanielsonFrameworkToList[data-lookupcodepk='" + lookupCodePK + "']").append(xhr.responseText);
                        _that.bindRemoveDanielsonButtons();
                        core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Added", 'success', 3000, null);
                    } else {
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("lookupCodePK=" + lookupCodePK + "&danielsonLookupPK=" + danielsonLookupCodePK + "&propertyFK=" + propertyPK);
            }
        }
    }

    removeDanielson(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.srcElement;
        let lookupCodePK = element.dataset.lookupcodepk;
        let danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;

        let allLookups = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.danielsonlookupcodepk === danielsonLookupCodePK) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    bindRemoveDanielsonButtons() {
        let allDeleteButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (let deleteButton of allDeleteButtons) {
            deleteButton.removeEventListener("click", (e: Event) => this.removeDanielson(e));
            deleteButton.addEventListener("click", (e: Event) => this.removeDanielson(e));
        }
    }

    reportTopicAreas() {
        let _that = this;
        let successes: boolean[] = [];
        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");

        for (let topicArea of allTopicAreas) {
            let topicAreaElement = <HTMLInputElement>topicArea;
            let topLevelAccordionElement = <HTMLDivElement>document.getElementById("inductionPlanTopicAreasAccordionList");
            let accordionTrigger = <HTMLButtonElement>topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            let accordionPanel = <HTMLDivElement>topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);

            if (topicAreaElement.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");
                }
            } else {
                let selects = accordionPanel.querySelectorAll("select");
                for (let select of selects) {
                    let selectElement = <HTMLSelectElement>select;
                    selectElement.value = "0";
                }

                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
                

                //TODO: Remove the danielson elements from the list.

            }
        }
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let hadDataElements = [];
        let allDanielsonElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanEducatorInductionPlanTopicAreasForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let timelines = document.getElementsByClassName("inductionPlanTopicAreasTimeline");

        for (let ele of timelines) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = 0
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let lookupCodePK = element.dataset.lookupcodepk;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.checked || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.dataset.timelinelookupcodepk),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        ButtonRow: parseInt(lookupCodePK),
                        DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let danielsonList = document.getElementsByClassName("addDanielsonFrameworkToListItem");

        for (let danielson of danielsonList) {
            let danielsonElement = <HTMLDivElement>danielson;

            let planPropertyPK = 0;
            let propertyPK = parseInt(danielsonElement.dataset.propertypk);
            let danielsonLookupCodePK = danielsonElement.dataset.danielsonlookupcodepk;
            let lookupCodePK = danielsonElement.dataset.lookupcodepk;
            if (danielsonElement.dataset.planpropertypk !== "" && danielsonElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(danielsonElement.dataset.planpropertypk);
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: "",
                LookupCodeFK: parseInt(danielsonLookupCodePK),
                RowNbr: 0,
                IsDeletedInd: false,
                ButtonRow: parseInt(lookupCodePK),
                DataPointPlanPropertyPK: parseInt(danielsonElement.dataset.datapointplanpropertypk)
            };

            allDanielsonElements.push(saveItem);
        }

        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        for (let topic of allTopicAreas) {
            let topicElement = <HTMLInputElement>topic;

            if (!topicElement.checked && topicElement.dataset.haddata && topicElement.dataset.haddata === "true") {
                hadDataElements.push(topicElement.dataset.planpropertypk);
            }
        }

        let saveData = {
            "Timeline": allSaveElements,
            "Danielson": allDanielsonElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveTopicAreas', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                //core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }

}

// Evaluation and Monitoring
class EvaluationAndMonitoring {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["evaluationAndMonitoringField"];

        let evaluationAndMonitoringSaveButton = document.getElementById("saveEvaluationAndMonitoring");
        if (evaluationAndMonitoringSaveButton !== null)
            evaluationAndMonitoringSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanEvaluationAndMonitoringForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("evaluationAndMonitoringField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEvaluationAndMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Documentation of Participation and Completion
class DocumentationOfParticipationAndCompletion {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["documentationOfParticipationField", "documentationOfParticipationRadioField"];

        let documentationOfParticipationAndCompletionSaveButton = document.getElementById("saveDocumentationOfParticipationAndCompletion");
        if (documentationOfParticipationAndCompletionSaveButton !== null)
            documentationOfParticipationAndCompletionSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanDocumentationOfParticipationAndCompletionForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("documentationOfParticipationField");
        let radioInputs = document.getElementsByClassName("documentationOfParticipationRadioField");

        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveDocumentationOfParticipationAndCompletion', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Educator Induction Plan Statement Of Assurance
class EducatorInductionPlanStatementOfAssurance {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["inductionPlanSignatureAndAssuranceField"];

        let EducatorInductionPlanStatementOfAssuranceSaveButton = document.getElementById("saveEducatorInductionPlanStatementOfAssurance");
        if (EducatorInductionPlanStatementOfAssuranceSaveButton !== null)
            EducatorInductionPlanStatementOfAssuranceSaveButton.addEventListener("click", (e: Event) => this.save("save"));


        let fileUploader = document.getElementById("inductionPlanUploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.srcElement));
        }

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
    }

    uploadFile(e: HTMLInputElement) {
        let core: Core = new Core();
        core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("inductionPlanUploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("inductionPlanUploadMinutesFile");
                        formFile.value = "";
                    } else {
                        core.hideLoader();
                        core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let formElement = document.getElementById("inductionPlanEducatorInductionPlanStatementOfAssuranceForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanSignatureAndAssuranceField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlanStatementOfAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Curriculum Summary Checklist and Submission
class InductionPlanSummaryChecklistAndSubmission {

    constructor() {

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core: Core = new Core();
            core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}