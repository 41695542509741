/// <reference path="core.ts" />
/// <reference path="interfaces/ready/Interfaces.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//Profile and Plan Essentials
class ProfileAndPlanEssentials {

    validationClasses: string[];

    constructor() {
        let core = new Core();
        this.validationClasses = ["ProfiePlanEssentials"];
        let saveButtonElement = document.getElementById("profileAndPlanSave");
        if (saveButtonElement !== null)
            saveButtonElement.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }

        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("profilePlanEssentialsData");
        let planPK = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("ProfiePlanEssentials");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        } 

        let processed = JSON.stringify(allSaveElements[0]);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveProfilePlanEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPK));
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
} 

//Steering Committee
class SteeringCommittee {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["steeringCommitteeField"];

        let steeringCommitteeAddRows = document.getElementById("steeringCommitteeAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e: Event) => this.steeringCommitteeAddRows());

        let steeringCommitteeSave = document.getElementById("saveCommitteeMembers");
        if (steeringCommitteeSave !== null)
            steeringCommitteeSave.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let wordPluralizer = document.getElementById("steeringCommitteeAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e: Event) => this.checkForPlural());

        var rows = document.getElementsByClassName("removeSteeringCommitteeRow");

        for (let row of rows) {
            row.addEventListener("click", (e: Event) => this.removeSteeringCommitteeRow(e));
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        let IsCompleteElement = <HTMLInputElement>document.getElementById("isComplete");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-rownumber]");
        let allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let planPK = 0;
        for (let row of steeringCommitteeTableRows)
        {
            let rowNumber = parseInt((<HTMLTableRowElement>row).dataset.rownumber);

            let inputs = row.querySelectorAll("input");

            for (let element of inputs) {
                let planPropertyPK = 0;
                planPK = parseInt(steeringCommitteeTable.dataset.planpk);
                let propertyPK = parseInt(element.dataset.propertypk);

                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    steeringCommitteeAddRows() {
        let core = new Core();
        let value = (<HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber"));
        let _that = this;

        if (value.value === "") {

        } else { 
            let rows = parseInt(value.value); 
            let row = 0;
            let table = (<HTMLTableElement>document.getElementById("steeringCommittee"));
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-rownumber")) > row)
                    row = parseInt(tr.getAttribute("data-rownumber"));
            }

            row++;

            for (let i = 0; i < rows; i++) {

                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Ready/SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        core.hideLoader();
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        value.value = "";

                        let rows = document.getElementsByClassName("removeSteeringCommitteeRow");
                        let row = rows[rows.length - 1];

                        row.addEventListener("click", (e: Event) => _that.removeSteeringCommitteeRow(e));
                        core.createHTMLAlert("alertMessageDiv", "New Steering Committee Row Added", 'success', 3000, null);
                    }
                    else {
                        core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("row=" + row);
                row++;
            }
        }
    } 

    removeSteeringCommitteeRow(e: Event) {
        let core = new Core();
        let button = <HTMLButtonElement>e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        let rowNumber = parent.dataset.rownumber;
        let hasData = button.dataset.hasdata;
        let planPK = 0;
        let allRemoveElements = [];

        if (hasData !== "") {

            planPK = parseInt(document.getElementById("steeringCommittee").dataset.planpk);

            let inputs = parent.querySelectorAll("input");

            for (let element of inputs) {

                let planPropertyPK = 0;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;

                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {

                    let removeItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: true
                    };

                    allRemoveElements.push(removeItem);

                }
                
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Ready/SaveSteeringCommittee', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    core.hideLoader();
                    //window.location.reload();
                    core.createHTMLAlert("alertMessageDiv", "Steering Committee Row Successfully Removed", 'success', 3000, core.pageReload);
                }
                else {
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(allRemoveElements));
        }

        parent.parentElement.removeChild(parent);
        core.createHTMLAlert("alertMessageDiv", "Steering Committee Row Successfully Removed", 'success', 3000, null);
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

}

var tinymce: any;

//LEAProfile
class LEAProfile {

    validationClasses: string[];

    constructor() {
        let core: Core = new Core();

        this.validationClasses = ["leaProfileField"];

        let saveButton = document.getElementById("saveLEAProfile");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");

    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let htmlElement = <HTMLTextAreaElement>document.getElementById("leaProfileDescription");
        let planPropertyPK = htmlElement.dataset.planpropertypk;
        let planPK = htmlElement.dataset.planpk;
        let propertyPK = htmlElement.dataset.propertypk;
        let value = tinymce.get('leaProfileDescription').getContent();

        var allSaveElements = [];
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }

        if (value !== "") {
            let saveItem: IPlanProperty = {
                PlanPropertyPK: parseInt(planPropertyPK),
                PlanFK: parseInt(planPK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, parseInt(planPK))
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//MissionAndVision
class MissionAndVision {

    validationClasses: string[];

    constructor() {
        let core: Core = new Core();
        this.validationClasses = ["missionAndVisionData"];
        let saveButton = document.getElementById("missionAndVisionSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);
        core.activateSubMenu("subNavReadySetGo");
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("missionAndVisionForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("missionAndVisionData");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let processed = JSON.stringify(allSaveElements[0]);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveMissionAndVision', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, parseInt(planPk))
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.doValidation(this.validationClasses);
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//EducationalValues
class EducationalValues {
    core: Core;
    validationClasses: string[];

    constructor() {
        this.core = new Core();

        this.validationClasses = ["educationalValuesDataElement"];

        let saveButton = document.getElementById("educationalValuesSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let continueButton = document.getElementById("continueButton");
        if (continueButton !== null)
            continueButton.addEventListener("click", (e: Event) => this.save("continue"));

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.save("back"));

        let otherOptional = document.getElementById("educationValuesOtherOptionalCheckbox");
        if (otherOptional !== null)
            otherOptional.addEventListener("click", (e: Event) => this.noPrograms("educationValuesOtherOptional", "educationValuesOtherOptionalCheckbox"));

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.core.doValidation(this.validationClasses);
        }
        this.core.initializeRequiredFields(this.validationClasses);
        this.core.activateSubMenu("subNavReadySetGo");
    }

    noPrograms(textarea: string, checkboxName: string) {
        let textArea = (<HTMLInputElement>document.getElementById(textarea));
        let checkbox = <HTMLInputElement>document.getElementById(checkboxName);

            if (checkbox.checked) {
                textArea.value = " ";
                textArea.readOnly = true;
            }
            else {
                textArea.readOnly = false;
                textArea.value = "";
            }
    }

    checkForCheckbox() {
            let textArea = (<HTMLInputElement>document.getElementById("educationValuesOtherOptional"));
            let checkbox = <HTMLInputElement>document.getElementById("educationValuesOtherOptionalCheckbox");
            if (checkbox.checked) {
                    textArea.readOnly = true;
            }
    }

    save(referrer) {
        let core = new Core();
        core.showLoader();

        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        let allSaveElements = [];
        let planElement = document.getElementById("educationalValuesForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("educationalValuesDataElement");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        allElements = document.getElementsByClassName("educationalValuesCheckElement");
        for (let element of allElements) {
            let htmlElement = <HTMLInputElement>element;
            let planPropertyPK: number = 0;
            let isChecked = "off";

            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }

            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        let processed = JSON.stringify(allSaveElements[0]);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveEducationalValues', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage; 
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}