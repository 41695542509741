/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

class PlanCommunications {

    validationClasses: string[];

    constructor() {
        let core = new Core();

        this.validationClasses = ["planCommunicationsField"];

        let saveButton = document.getElementById("savePlanCommunications");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e: Event) => this.save("save"));

        let printButton = document.getElementById("printPlanCommunications");
        if (printButton !== null)
            printButton.addEventListener("click", (e: Event) => this.print());

        let addCommunicationPlanButton = document.getElementById("addCommunicationPlan");
        if (addCommunicationPlanButton !== null)
            addCommunicationPlanButton.addEventListener("click", (e: Event) => this.addPlanCommunication());

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            core.doValidation(this.validationClasses);
        }
        core.initializeRequiredFields(this.validationClasses);

        this.bindDeleteButtons();
        this.bindModalButtons();

        core.activateSubMenu("subNavPlanCommunications");
    }

    print() {

    }

    save(referrer) {
        let core = new Core();
        core.showLoader();
       
        let allSaveElements = [];
        let refreshPage = "";

        let formData = document.getElementById("planCommunicationsData");
        let planPK = parseInt(formData.dataset.planpk);
        let allElements = document.getElementsByClassName("planCommunicationsField");

        for (let ele of allElements) {
            let element = <HTMLInputElement>ele;
            let planPropertyPK = parseInt(element.dataset.planpropertypk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let value = element.value;
            let row = parseInt(element.dataset.row);

            if (element.value !== "") {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: row,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanCommunications/SavePlanCommunications', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        core.hideLoader();
                }
                else {
                    core.hideLoader();
                    core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deletePlanCommunication");

        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeletePlanCommunicationNotification(e));
        }
    }

    bindModalButtons() {
        let planCommunicationDeletePlanCommunicationCancelButton = document.getElementById("planCommunicationDeletePlanCommunicationCancel");
        if (planCommunicationDeletePlanCommunicationCancelButton !== null) {
            planCommunicationDeletePlanCommunicationCancelButton.addEventListener("click", (e: Event) => this.cancelDeletePlanCommunicationNotification());
        }

        let planCommunicationDeletePlanCommunicationConfirmButton = document.getElementById("planCommunicationDeletePlanCommunicationConfirm");
        if (planCommunicationDeletePlanCommunicationConfirmButton !== null) {
            planCommunicationDeletePlanCommunicationConfirmButton.addEventListener("click", (e: Event) => this.deletePlanCommunication());
        }
    }

    showDeletePlanCommunicationNotification(e: Event) {
        let modal: Modal = new Modal("planCommunicationDeletePlanCommunication", null);
        let buttonElement = <HTMLButtonElement>e.srcElement;
        let row = buttonElement.dataset.row;
        modal.addAttributeToElement("planCommunicationDeletePlanCommunication", "#planCommunicationDeletePlanCommunicationConfirm", "row", row);
        modal.show();
    }

    cancelDeletePlanCommunicationNotification() {
        let modal: Modal = new Modal("planCommunicationDeletePlanCommunication", null);
        modal.hide();
    }

    deletePlanCommunication() {
        let deleteButtonElement = <HTMLButtonElement>document.getElementById("planCommunicationDeletePlanCommunicationConfirm");
        let row = deleteButtonElement.dataset.row;
        let planProps = [];
        let core: Core = new Core();
        let formData = document.getElementById("planCommunicationsData");
        let planPK = parseInt(formData.dataset.planpk);

        core.showLoader();

        let communicationFields = document.getElementsByClassName("planCommunicationsField");
        for (let comm of communicationFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanCommunications/DeleteCommunicationPlanRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                core.hideLoader();

                let allTheRows = document.getElementsByClassName("planCommunicationRow");
                for (let thisRow of allTheRows) {
                    let thisRowElement = <HTMLDivElement>thisRow;
                    if (thisRowElement.dataset.row === row) {
                        thisRowElement.parentNode.removeChild(thisRowElement);
                    }
                }

                core.createHTMLAlert("alertMessageDiv", "This communication plan has been removed.", 'success', 3000, core.pageReload(false, planPK));
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    addPlanCommunication() {
        let core = new Core();
        let newRow: number = 0;
        let _that = this;

        let communicationFields = document.getElementsByClassName("planCommunicationsField");
        for (let comm of communicationFields) {
            let commElement = <HTMLInputElement>comm;
            if (newRow < parseInt(commElement.dataset.row)) 
                newRow = parseInt(commElement.dataset.row);
        }

        newRow++;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanCommunications/AddCommunicationPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#planCommunications > div > div").append(xhr.responseText);
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "New Plan Communication added", 'success', 3000, null);
                _that.bindDeleteButtons();
            } else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newRow=" + newRow);
    }
}