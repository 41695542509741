/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

//SummaryChecklistAndSubmissionCompPlan
class SummaryChecklistAndSubmissionCompPlan {
    constructor() {
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    submit(e: Event) {
        let planFK = 0;
        let element = <HTMLButtonElement>e.srcElement;
        planFK = parseInt(element.dataset.planfk);
        let core: Core = new Core();
        core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SummaryAndSubmission/SubmitPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Your plan has been submitted for review!", 'success', 3000, window.location.reload);
            }
            else {
                core.hideLoader();
                core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK);
    }
}